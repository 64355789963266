import {Router} from 'vue-router';
import {getApmInstance, isApmReady} from './utils';

/**
 * 为 vue-router 实例添加 apm 性能统计相关的拦截器
 * @description https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/HDeySYhuyv/O-RKEhHIxVKcH6#anchor-57143fd0-b252-11ec-b34c-6bfd89749842
 * @param router
 */
export default function useApmRouter(router: Router) {
    router.beforeEach((to, from) => {
        if (isApmReady() && to.name !== from.name) {
            const newOptId = getApmInstance().getNewId();
            // apm 刷新 optid（每个页面独占一个 optid 记录当前页面的操作链路）
            getApmInstance().setOptId(newOptId);
            console.info('current page optid:', newOptId);
            getApmInstance().logStatic(0, to.path);
        }
    });

    router.afterEach((to, from) => {
        if (isApmReady() && to.name !== from.name) {
            getApmInstance().logStatic(1, to.path);
        }
    });
}
