import {IUserInfoRes} from '../interface/user';
import {API} from '@/base/http';
import {IS_NODE} from '@/base/utils';

const PATHS = {
    detail: '/waimao/mall/open/service/user/info',
};

/**
 * 获取用户信息
 * api: https://yapi.baidu-int.com/project/25966/interface/api/1439560
 * @param headers
 * @returns IUserInfoRes
 */
export const getUserDetail = headers => {
    const cfg = IS_NODE ? {
        cookie: headers.ck,
        host: headers.host,
    } : {};

    return API.get<IUserInfoRes>(PATHS.detail, {
        baseURL: '/nodeapi/auth/validate/proxy',
        silent: true,
        headers: {
            ...cfg,
        },
    });
};
