import {Module} from 'vuex';
import {IRootState} from '.';
import {IDetailHtmlRes, ISpuDetailRes} from '@/base/api/interface/order';

export interface IOrderState {
    mergeDetailHtmlData: IDetailHtmlRes | undefined;
    mergeDetailData: ISpuDetailRes | undefined;
}

export enum OrderMutationEnum {
    SET_MERGE_ORDER_HTML_DATA = 'SET_MERGE_ORDER_HTML_DATA',
    SET_MERGE_ORDER_DATA = 'SET_MERGE_ORDER_DATA',
}

function createOrderStateModule(): Module<IOrderState, IRootState> {
    return {
        namespaced: true,
        state: {
            mergeDetailHtmlData: undefined,
            mergeDetailData: undefined,
        },
        mutations: {
            [OrderMutationEnum.SET_MERGE_ORDER_HTML_DATA](state, payload: IOrderState['mergeDetailHtmlData']) {
                state.mergeDetailHtmlData = payload;
            },
            [OrderMutationEnum.SET_MERGE_ORDER_DATA](state, payload: IOrderState['mergeDetailData']) {
                state.mergeDetailData = payload;
            },
        },
    };
}

export default createOrderStateModule;
