/* eslint-disable no-console */
/**
 * @file 接口请求结果处理
 * @author liuqifeng01@baidu.com
 * @date 2021.06.11
 */

import {AxiosResponse} from 'axios';
import {captureApiException} from '../utils/report';
import {ERROR_MSG, SERVER_ERROR_MSG, UN_KNOW_ERR_CODE} from './errorMsg';
import {IAxiosRequestConfig, IAxiosResponse} from '.';
import {showElMessage} from '@/base/utils/message';
import {useRootStore} from '@/pinia';

const MESSAGE_OFFSET = 100;

/** http://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/9nUaBjaVOI/_6NXolIXzzb-Wc */
const SERVER_MESSAGE_CODES = [2004, 4000];

const CUSTOM_ERR_CODES = [4001];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (
    res: AxiosResponse & {
        config: IAxiosRequestConfig;
    }
) => {
    const store = useRootStore();
    // 因为在创建请求实例时设置了 validateStatus: true 所以 >= 400 的相应也会被 resolve
    // res.status >= 400 这种异常提示
    if (!res || (res && !res.status)) {
        store.isDataErrorFromApiFetch = true;
        captureApiException({
            url: `${res?.config.baseURL}${res?.config.url}`,
            method: res?.config?.method || '',
            params: res?.config?.params,
            status: 0,
            response: undefined,
            // user: store.state.user?.info?.nick_name,
            extra: {
                headers: res?.config?.headers,
            },
        });
        // 请求超时 or 无响应
        return Promise.reject('网络异常');
    }

    if (res.status >= 400) {
        store.isDataErrorFromApiFetch = true;
        const errorMsg = SERVER_ERROR_MSG[res.status] || SERVER_ERROR_MSG[UN_KNOW_ERR_CODE];

        // 不静默时使用 message 提示
        if (!res.config?.silent) {
            showElMessage({
                type: 'error',
                offset: MESSAGE_OFFSET,
                message: errorMsg,
            });
        }

        captureApiException({
            url: `${res?.config?.baseURL}${res?.config?.url}`,
            method: res.config.method!,
            params: res.config.params,
            status: res.status,
            response: res.data,
            // user: store.state.user?.info?.nick_name,
            extra: {
                errorMsg,
                headers: res.config.headers,
            },
        });

        console.log('ERROR', errorMsg);
        return Promise.reject(errorMsg);
    }

    const data: IAxiosResponse = res.data || {};

    // 爱采购的接口返回的是 {status, msg, data, logId}
    let errno = Number(data.errno ?? data.status);

    if (isNaN(errno)) {
        errno = UN_KNOW_ERR_CODE;
    }

    if (errno !== 0) {
        store.isDataErrorFromApiFetch = true;
        if (!res.config.silent) {
            // 服务器直出错误信息
            if (SERVER_MESSAGE_CODES.includes(errno)) {
                // 需要展示服务端错误信息
                showElMessage({
                    type: 'error',
                    offset: MESSAGE_OFFSET,
                    message: res.data.errmsg,
                });
            }
            else if (!CUSTOM_ERR_CODES.includes(errno)) {
                const errorMsg = ERROR_MSG[errno] || ERROR_MSG[UN_KNOW_ERR_CODE];

                showElMessage({
                    type: 'error',
                    offset: MESSAGE_OFFSET,
                    message: errorMsg,
                });
            }
        }
        // TODO: 接口的try ... catch对应的改动
        // 如果接口的请求 confing 设置了 isReturnResolveWhenErrorcode = true 则使用 resolve 返回
        return res.config?.isReturnResolveWhenErrorcode
            ? Promise.resolve(data)
            : Promise.reject(data);
    }

    return Promise.resolve(data);
};
