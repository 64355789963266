/** 仅在客户端会渲染的组件 */
import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
  name: 'ClientOnly',
  setup() {
    let mounted = ref(false);
    onMounted(() => {
      mounted.value = true;
    });
    return {
      mounted
    };
  }
});