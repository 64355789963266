import {IEncryptFontMap, IEncryptFontSource} from './types';

export function getRandomFontSource(fontSource: IEncryptFontSource | IEncryptFontSource[]) {
    if (fontSource instanceof Array) {
        const randomIndex = Math.floor(Math.random() * fontSource.length);
        return fontSource[randomIndex];
    }
    return fontSource;
}

export function createFontStyleElement(fontMap: IEncryptFontMap) {
    const styleElement = document.createElement('style');
    let styleStr = '';
    fontMap.forEach((currentFont, className) => {
        styleStr += `
            @font-face {
                font-family: '${className}';
                src:
                    ${currentFont.url.woff ? `url('${currentFont.url.woff}') format('woff'),` : ''}
                    ${currentFont.url.woff2 ? `url('${currentFont.url.woff2}') format('woff2'),` : ''}
                    url('${currentFont.url.ttf}') format('truetype');
            }
            .${className} {
                font-family: '${className}' !important;
            }
        `;
    });
    // bca-disable-line
    styleElement.innerHTML = styleStr;
    return styleElement;
}

function getDecodeStep(str: string, startIndex: number) {
    let decodeKey = str.substring(startIndex, startIndex + 2);
    return parseInt(decodeKey, 16);
}

export function decodeEncryptFont(str: string, startIndex: number = 0) {
    let result = '';
    const decodeStep = getDecodeStep(str, startIndex);
    for (let i = startIndex + 2; i < str.length; i += 2) {
        let currentDecodeStep = getDecodeStep(str, i) ^ decodeStep;
        result += String.fromCharCode(currentDecodeStep);
    }
    result = decodeURIComponent(encodeURI(result));
    return result;
}
