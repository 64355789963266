/**
 * @file url 的工具函数集
 * @author liuqifeng01.baidu.com
 *
 * @function getQueryInVueRouteQuery: 在 vueroute 的 query 对象里获取某个属性的值，返回这个属性的值
 * @function removeQueryInVueRouteQuery: 在 vueroute 的 query 对象里删除某个属性的值，然后返回新的 query 对象
 *
 * TODO 单测
 */

import {IS_NODE} from '.';

type ILocationQueryValue = string | null;
interface IRouteQuery {
    [key: string]: ILocationQueryValue | ILocationQueryValue[];
}

/**
 * 在使用 vue-router 的获取的 query 对象里获取某个参数的值
 * @param {routeQuery: string || string[]}
 * @param {name: string} 要获取的某个
 *
 * TODO 添加单测
 */

export function getQueryInVueRouteQuery<T extends IRouteQuery, K extends keyof T>(routeQuery: T, name: K): string {
    let query = routeQuery[name] || '';
    if (Array.isArray(query)) {
        query = query[0] || '';
    }
    return query as string;
}

/**
 * 在 vueroute 的 query 对象中删除某些属性
 * @param {name: string | [] } 要删除的属性名
 * @param {routeQuery: object} vueroute 的 query 对象
 * @return 新 query 对象
 */
export function removeQueryInVueRouteQuery<T extends IRouteQuery, K extends keyof T>(
    routeQuery: T,
    name: K
): IRouteQuery {
    let queryObj = JSON.parse(JSON.stringify(routeQuery));

    if (typeof name === 'string') {
        delete queryObj[name];
    }
    else if (Array.isArray(name)) {
        name.forEach(key => {
            delete queryObj[key];
        });
    }

    return queryObj;
}

/**
 * 获取 url 参数
 *
 * @export
 * @param {string} name 参数 key 值，必须
 * @param {string} url 资源路径 URI，默认获取当前页面路径
 * @returns {string} 对应参数的 value 值
 *
 * TODO 添加单测
 */
export function getQueryString(name: string, url?: string): string {
    const regex = new RegExp('(?:^|\\?|#|&)' + name + '=([^&#]*)(?:$|&|#)', 'i');
    const m = regex.exec(url || window.location.href);
    return m ? decodeURIComponent(m[1]) : '';
}

/**
 * 获取请求资源的网络地址
 * @param {string} path 要获取的内容相对于host的文件路径
 * @return {string} 请求资源的网络地址
 */
export function handleUrl(path: string): string {
    if (process.env.APP_MODE === 'production' || process.env.APP_MODE === 'online') {
        return `https://chuhaiyi.baidu.com/${path}`;
    }
    if (IS_NODE) {
        return `/${path}`;
    }
    const loc = window.location;
    return `${loc.protocol}//${loc.host}/${path}`;
}

/**
 * 创建一个带有协议的 url，如果传入的字符串本身带有协议则不做处理
 * 如：www.baidu.com => //www.baidu.com
 *    https://www.baidu.com => https://www.baidu.url
 *    mailto://www.baidu.com => mailto://www.baidu.url
 * @param str
 * @param defaultProtocolUrl
 * @returns
 */
export function createProtocolUrl(str: string, defaultProtocolUrl = '//') {
    if (typeof str === 'string') {
        const protocolUrlReg = /^(.+?:)?\/\//;
        if (protocolUrlReg.test(str)) {
            return str;
        }
        return defaultProtocolUrl + str;
    }
    return defaultProtocolUrl;
}
