
import {IS_BROWNER} from '.';

// 主动上报错误; 如：code 码错误
export const captureException = (err: Error) => {
    if (!IS_BROWNER) {
        return;
    };
    if (window.__Weirwood && window.__Weirwood?.error?.captureException) {
        window.__Weirwood?.error?.captureException(err);
    }
};

// 主动上报 API 错误;
export const captureApiException = (params: ICaptureExceptionError) => {
    if (!IS_BROWNER) {
        return;
    };
    if (window.__Weirwood && window.__Weirwood?.error?.captureAPIException) {
        window.__Weirwood?.error?.captureAPIException(params);
    }
};
