import {Module} from 'vuex';
import {IRootState} from '.';
import {SeoListRes} from '@/base/api/interface/seolist';
import type {IGetNewsListRes} from '@/base/api/interface/news';

export interface ISeoListState {
    seoListData: SeoListRes | undefined;
    seoSearchData?: IGetNewsListRes;
}

export enum SeoListMutationEnum {
    SET_SEO_LIST_DATA = 'SET_SEO_LIST_DATA',
    SET_SEO_SEARCH_DATA = 'SET_SEO_SEARCH_DATA',
}

function createSeoListStateModule(): Module<ISeoListState, IRootState> {
    return {
        namespaced: true,
        state: {
            seoListData: undefined,
            seoSearchData: undefined,
        },
        mutations: {
            [SeoListMutationEnum.SET_SEO_LIST_DATA](state, payload: ISeoListState['seoListData']) {
                state.seoListData = payload;
            },

            [SeoListMutationEnum.SET_SEO_SEARCH_DATA](state, payload: ISeoListState['seoSearchData']) {
                state.seoSearchData = payload;
            },
        },
    };
}

export default createSeoListStateModule;
