import {IS_NODE} from '@/base/utils';

export function isApmReady() {
    return !!getApmInstance();
}

export function getPageUrl() {
    // "/path/from/url/" => "/path/from/url"
    if (!IS_NODE) {
        let path = window.location.pathname.replace(/\/$/, '');
        if (!path) {
            path = '/';
        }
        return path;
    }
}


/**
 * 获取 APM 实例
 */
export function getApmInstance(): any {
    if (!IS_NODE) {
        return window.apm;
    }
    return false;
}
