import {v4} from 'uuid';

class Trace {
    traceid;

    buildTraceid() {
        this.traceid = v4();
        return this.traceid;
    }

    getTraceid() {
        return this.traceid || this.buildTraceid();
    }

    setTraceid(id) {
        if (id) {
            this.traceid = id;
        }
    }
}

const trace = new Trace();

export {trace};