/**
 * @file 请求封装
 */

import axios, {AxiosRequestConfig, AxiosInstance} from 'axios';
import qs from 'qs';
import {v4} from 'uuid';
import {stringifyQuery} from '@baidu/b2b-util';
import {IS_NODE} from '../utils';
import {CacheType} from '../utils/cache';
import {LRUCache} from '../utils/lru';
import {nodeLog} from '../utils/log';
// import {getConfig, BASE_API} from '../../../env.config';
import {trace} from '../log/trace';
import {IAPI, IApiUrl} from '../api/interface';
import useApmRequest from '../hooks/useApm/useApmRequest';
import handleResponse from './handleResponse';

const {getConfig, BASE_API} = require('../../../env.config.ts');
// 客户端读到的 config 中 host 不对、因为是在编译时注入的环境变量
// const cfg = getConfig();
export interface IAxiosRequestConfig extends AxiosRequestConfig {
    silent?: boolean;
    cached?: CacheType;
    metadata?: {
        startTime?: number;
    };
    path?: string;
    // code 码不为 0 时，使用 resolve 返回，默认是 false，使用 reject 返回;
    isReturnResolveWhenErrorcode?: boolean;
    // 是否是 b2b post 请求，b2b post 请求与出海易参数格式不同
    isB2bPost?: boolean;
}

const service: AxiosInstance = axios.create({
    baseURL: IS_NODE ? `${BASE_API}` : BASE_API,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        post: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        common: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    },
    transformRequest: [
        (data: IAxiosRequestConfig['data'], headers: IAxiosRequestConfig['headers']) => {
            if (typeof data === 'string') {
                return data;
            }

            const contentType = headers['Content-Type'];

            if (/\bapplication\/json\b/i.test(contentType)) {
                return JSON.stringify(data);
            }
            if (/\bmultipart\/form-data\b/.test(contentType)) {
                return data;
            }

            return data;
        },
    ],
    validateStatus() {
        return true;
    },
});

/**
 * 构建默认参数
 */
const getDefaultParams = (data?: {[k: string]: any}) => {
    // TODO 根据实际业务获取哪些默认参数
    // const query = getQuery(window.location.href);
    const params = {
        // 需要带的默认参数写在这，比如 csrf 相关参数
        // token: store.getters.token || undefined,
        // xxx: query.xxx || ''
        ...data,
    };
    return params;
};

if (!IS_NODE) {
    useApmRequest(service);
}

/**
 * 请求拦截
 */
service.interceptors.request.use(
    (config: IAxiosRequestConfig) => {
        // 爱采购的 POST 接口时 将参数转为KV的Form data
        if (config.isB2bPost) {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            config.transformRequest = [data => stringifyQuery(data)];
        }

        config.headers['X-Request-Id'] = v4();
        config.metadata = {startTime: new Date().getTime()};

        if (IS_NODE && process.env.APP_MODE) {
            // node 测超时放短一些
            // config.timeout = 1000;

            const nodeCfg = getConfig();

            console.info('[服务端请求]', config.baseURL, config.url, 'trace-id', config.headers['X-Request-Id']);

            Object.keys(nodeCfg.proxy).forEach(key => {
                if (RegExp(key).exec(config.baseURL!) || RegExp(key).exec(`${config.baseURL}${config.url}`)) {
                    const proxy = nodeCfg.proxy[key];
                    const host = proxy.target;
                    let baseurl = config.baseURL;
                    if (proxy.pathRewrite) {
                        Object.keys(proxy.pathRewrite).forEach(k => {
                            baseurl = config.baseURL?.replace(k, proxy.pathRewrite[k]);
                        });
                    }

                    if (proxy.headers?.['x-service-name']) {
                        config.withCredentials = true;
                        if (config.headers.host) {
                            // eslint-disable-next-line no-console
                            config.headers.proxy_host = config.headers.host;
                            delete config.headers.host;
                        }
                        config.headers['x-service-name'] = proxy.headers?.['x-service-name'];
                    }

                    if (proxy.headers?.Referer) {
                        config.headers.Referer = proxy.headers?.Referer;
                    }

                    console.info(`rewrite => ${host}${baseurl}${config.url}`, host);
                    config.baseURL = `${host}${baseurl}`;
                }
            });
        }
        const params = config.method === 'get' ? config.data : {};
        config.url = formatUrlParams(config.url as string, params);

        // config.params = filterXSS(config.params);
        // config.data && (config.data = filterXSS(config.data));

        return config;
    },
    error => Promise.reject(error)
);

/**
 * 响应拦截
 */
service.interceptors.response.use(
    res => {
        const config = (res.config || {}) as IAxiosRequestConfig;
        const endTime = new Date().getTime();
        const startTime = config?.metadata?.startTime;
        // 设置traceid
        trace.setTraceid(res.data?.traceid || res.config?.headers?.['X-Request-Id']);

        if (IS_NODE) {
            nodeLog(
                'info',
                'method:',
                config.method,
                'tid:',
                config.headers['X-Request-Id'] || config.headers['x-trace-id'] || '',
                'servername:',
                config.headers['x-service-name'] || '-',
                'url:',
                config.url,
                'response-time:',
                startTime ? endTime - startTime : '-',
                'ms',
                'logpos: /src/base/http/index.ts'
            );
        }

        return handleResponse(res);
    },
    error => {
        const xTraceId = error.response.headers['x-request-id'] || error.response.headers['x-trace-id'] || '';
        if (IS_NODE) {
            nodeLog('error', '==== onProxyError ====', 'tid:', xTraceId, 'logpos: /src/base/http/index.ts', error);
        }
        return handleResponse(error.response);
    }
);

const formatUrlParams = (url: string, data?: {[k: string]: any}) => {
    const defaultParamsStr = qs.stringify({...getDefaultParams(), ...data});

    const prefix = url?.includes('?') ? '&' : '?';

    return `${url}${defaultParamsStr ? prefix : ''}${defaultParamsStr}`;
};

export interface IAxiosResponse<T = any> {
    data: T;
    errno: 0 | number;
    errmsg: string;
    traceid: string;
    // status, msg，logId 是请求爱采购的接口时返回的
    status?: number;
    msg?: string;
    logId?: string;
}

const cache = new LRUCache(20);

export const API = {
    get: async <T>(url: string, config?: IAxiosRequestConfig) => {
        const {cached = false, ...axiosConfig} = config || {};
        const reqUrl = `${url}?${qs.stringify(axiosConfig?.params)}`;

        // node 不走缓存
        if (cached && !IS_NODE && cache.get(reqUrl, cached)) {
            console.info('info', '[命中缓存]', reqUrl);
            return cache.get(reqUrl, cached) as IAxiosResponse<T>;
        }

        const res = (await service.get(url, axiosConfig)) as IAxiosResponse<T>;

        if (cached && !IS_NODE) {
            console.info('info', `需要缓存、缓存位置: ${reqUrl}, 缓存类型: ${cached}`);
            cache.set(reqUrl, res, cached);
        }

        return res;
    },
    post: <T>(url: string, config?: IAxiosRequestConfig) => {
        return service.post(url, config?.data, config) as Promise<IAxiosResponse<T>>;
    },
};

export type IHttpGetConfig<URL extends IApiUrl> = Omit<IAxiosRequestConfig, 'params'> & {params?: IAPI[URL]['req']};
export type IHttpPostMethodConfig<URL extends IApiUrl> = Omit<IAxiosRequestConfig, 'data'> & {
    data?: IAPI[URL]['req'];
};

export const FETCH_API = {
    get: async <URL extends IApiUrl>(
        url: URL,
        config?: IHttpGetConfig<URL>
    ): Promise<IAxiosResponse<IAPI[URL]['res']>> => {
        const {cached = false, ...axiosConfig} = config || {};
        const reqUrl = `${url}?${qs.stringify(axiosConfig?.params)}`;

        // node 不走缓存
        if (cached && !IS_NODE && cache.get(reqUrl, cached)) {
            console.info('info', '[命中缓存]', reqUrl);
            return cache.get(reqUrl, cached);
        }

        const res = (await service.get(url, axiosConfig)) as IAxiosResponse<IAPI[URL]['res']>;

        if (cached && !IS_NODE) {
            console.info('info', `需要缓存、缓存位置 ${cached}`);
            cache.set(reqUrl, res, cached);
        }

        return res;
    },
    post: async <URL extends IApiUrl>(
        url: URL,
        config: IHttpPostMethodConfig<URL>
    ): Promise<IAxiosResponse<IAPI[URL]['res']>> => {
        const {data, ...axiosConfig} = config || {};
        return service.post(url, data, axiosConfig);
    },
};

export default service;
