import {ElMessage as _showElMessage, MessageOptions} from 'element-plus';
import {normalizeClass} from 'vue';
import {isMobileUa, IS_NODE} from '.';

export function showElMessage(option: Partial<MessageOptions>) {
    if (IS_NODE) {
        return;
    }
    const IS_MOBILE = isMobileUa();
    // function getIcon() {
    //     if (!IS_MOBILE) {
    //         return undefined;
    //     }
    //     switch (option.type) {
    //         case 'error':
    //             return CircleClose;
    //         case 'success':
    //             return CircleCheck;
    //         case 'warning':
    //             return Warning;
    //         // TODO: 补全移动端 icon
    //         case 'info':
    //         default:
    //             return undefined;
    //     }
    // }

    let elMessageContainerEl = document.getElementById('el-message-container__mobile');

    if (IS_MOBILE && !elMessageContainerEl) {
        elMessageContainerEl = document.createElement('div');
        elMessageContainerEl.id = 'el-message-container__mobile';
        document.body.appendChild(elMessageContainerEl);
    }

    _showElMessage({
        // icon: getIcon() as any,
        ...option,
        customClass: normalizeClass([option.customClass, IS_MOBILE ? 'el-message__mobile' : null]),
        // 移动端不支持主动设置 appendTo，后续有需求需要在此设置
        appendTo: option?.appendTo ?? ((IS_MOBILE ? elMessageContainerEl : undefined) as any),
    });
}
