import {useSSRContext} from 'vue';
import {IS_NODE} from '../utils';
import {ISSRCtx} from '@/entry/entry.server';

/**
 * 返回安全的 SSRContext 对象，避免在客户端 Hydration 时报错
 */
export function useSafeSSRContext() {
    let ctx: Record<string, any> = {};
    if (IS_NODE) {
        ctx = useSSRContext() as ISSRCtx;
    }
    return ctx as ISSRCtx;
}
