import { defineComponent, provide } from 'vue';
import { useHead } from '@vueuse/head';
import { ID_INJECTION_KEY } from 'element-plus';
import { DEFAULT_SEO_CONFIG } from '@/base/config/seo';
import { useLogin } from '@/base/hooks/useLogin';
// import {TRIAL_TYPE_ENUM} from '@/views/components/Freetrial/types';
// import {useToTrial} from '@/views/components/Freetrial/useToTrial';
// import Freetrial from '@/views/components/Freetrial/Index.vue';
export default defineComponent({
  name: 'App',
  setup() {
    provide(ID_INJECTION_KEY, {
      prefix: 0,
      current: 0
    });
    useHead(DEFAULT_SEO_CONFIG);
    useLogin();
    // 试用
    // const trialRef = ref<InstanceType<typeof Freetrial> | null>(null);
    // const {trialType, trialTrigger} = useToTrial(trialRef);
    return {
      // trialRef,
      // trialType,
      // trialTrigger,
      // TRIAL_TYPE_ENUM,
    };
  }
});