import type {Module} from 'vuex';
import {IRootState} from '.';
import {IGetNewsListRes, INewsDetail, INewsCommodityListRes} from '@/base/api/interface/news';
import {IHomeRes} from '@/base/api/interface/home';

export interface INewsState {
    newsSearchData: IGetNewsListRes | undefined;
    mergeHomeData: IHomeRes | undefined;
    newsDetailData: INewsDetail | undefined;
    newsCommodityListData: INewsCommodityListRes | undefined;
}

export enum NewsMutationEnum {
    SET_NEWS_SEARCH_DATA = 'SET_NEWS_SEARCH_DATA',
    SET_MERGE_HOME_DATA = 'SET_MERGE_HOME_DATA',
    SET_NEWS_DETAIL_DATA = 'SET_NEWS_DETAIL_DATA',
    SET_NEWS_COMMODITY_LIST_DATA = 'SET_NEWS_COMMODITY_LIST_DATA',
}

function createNewsState(): Module<INewsState, IRootState> {
    return {
        namespaced: true,
        state: {
            newsSearchData: undefined,
            mergeHomeData: undefined,
            newsDetailData: undefined,
            newsCommodityListData: undefined,
        },
        mutations: {
            [NewsMutationEnum.SET_NEWS_SEARCH_DATA](state, payload: INewsState['newsSearchData']) {
                state.newsSearchData = payload;
            },
            [NewsMutationEnum.SET_MERGE_HOME_DATA](state, payload: INewsState['mergeHomeData']) {
                state.mergeHomeData = payload;
            },
            [NewsMutationEnum.SET_NEWS_DETAIL_DATA](state, payload: INewsState['newsDetailData']) {
                state.newsDetailData = payload;
            },
            [NewsMutationEnum.SET_NEWS_COMMODITY_LIST_DATA](state, payload: INewsState['newsCommodityListData']) {
                state.newsCommodityListData = payload;
            },
        },
    };
}

export default createNewsState;
