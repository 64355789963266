import {App} from 'vue';
import encryptFontPlugin from '@/base/plugin/encryptFont';
import {IEncryptFontOption} from '@/base/plugin/encryptFont/types';

export function useEncryptFontPlugin(app: App<Element>) {
    app.use(encryptFontPlugin, {
        fonts: [
            {
                source: [
                    {
                        url: {ttf: require('@/assets/font/encrypt/b9b69c62ec91497384a95788dc82532b.ttf')},
                        code: 0,
                    },
                    {
                        url: {ttf: require('@/assets/font/encrypt/3c68c184076549978534ce7d7f5918b9.ttf')},
                        code: 1,
                    },
                    {
                        url: {ttf: require('@/assets/font/encrypt/25134ff2effb40eb85839b3684e22199.ttf')},
                        code: 2,
                    },
                    {
                        url: {ttf: require('@/assets/font/encrypt/d19903f0eb374f079b6472178cd36f47.ttf')},
                        code: 3,
                    },
                ],
                class: 'number-enchar-encrypt',
            },
        ],
    } as IEncryptFontOption);
}
