/**
 * @file sclog 神策埋点上报方法
 * @author yangruifeng@baidu.com
 *
 * @exports scLog 使用 log 方法
 *
 */

import qs from 'query-string';
import {trace} from '../log/trace';
import {isMobileUa, IS_NODE} from '@/base/utils';
import {useStore, getSpiderInfo} from '@/store';


// 字段含义文档 https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/8oO2Ot4AYz/ryD3Qn_giP6qA9
// 神策埋点数据解码平台：https://www.sensorsdata.cn/tools/decode.html

export interface SCLOGPARAMS {
    tpl?: string;
    biz?: string;
    act?: string;
    accessid?: string;
    page?: string;
    prepage?: string;
    from?: string;
    ofr?: string;
    elem?: string;
    pos?: string;
    currentpage?: string;
    productlist?: string;
    traceid?: string;
    articleId?: string | number;
    articleCategory?: string;
    query?: string;
    spiderType?: string;
    // ai 生成文章的生成方式
    generalType?: number;
    // 额外的一些参数，一个 JSON stringify 后的对象字符串 如：JSON.stringify({'name': 'zhangsan'})
    ext?: string;
}

// NOTICE 尽量不要用 'show', 因为这个事件上报的数据没有验证过在神策数据平台里能不能选择展示出来
type ACTTYPE = 'pv' | 'click' | 'show';

const getDeviceType = () => {
    if (isMobileUa()) {
        return 'wise';
    }
    return 'pc';
};

let initLogParams = {
    tpl: 'trade-bms',
    biz: getDeviceType(),
    act: 'pv',
    // accessid: '',
    // prepage 通过路由设置
    // page 通过路由设置
    // from,
    // ofr,
    // url: encodeURIComponent(location.href),
    traceid: trace.buildTraceid(),
};

export const setLog = (params?: SCLOGPARAMS) => {
    initLogParams = {
        ...initLogParams,
        ...params,
    };
};

export const scLog = (actType: ACTTYPE, params?: SCLOGPARAMS) => {
    const store = useStore();
    const spiderInfo = store?.state?.spiderInfo
        ?? (!IS_NODE && getSpiderInfo(undefined, window?.navigator?.userAgent));


    if (!IS_NODE) {
        const actTypeInfo = {
            'pv': 'chy_pv',
            'click': 'chy_click',
            'show': 'chy_show',
        };

        const query =  qs.parse(location.search.split('?')[1]);
        // eslint-disable-next-line no-unused-expressions
        window.sensors && window.sensors.track(actTypeInfo[actType], {
            ...initLogParams,
            act: actType,
            from: query?.from,
            ofr: query?.ofr,
            url: encodeURIComponent(location.href),
            spiderType: spiderInfo?.spiderType ? spiderInfo.spiderType : 'browser',
            ...params,
        });

        // eslint-disable-next-line no-unused-expressions
        // window.sensors && window.sensors.track('test', {
        //     ...getInitLogParams(),
        //     act: actType,
        //     ...params,
        // });
    }
};
