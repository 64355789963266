import type {InjectionKey} from 'vue';
import {Store} from 'vuex';
import type {Module} from 'vuex';
import {IRootState} from '.';
import {getUserDetail} from '@/base/api/user';
import {TRIAL_STATUS_ENUM, USER_TYPE_ENUM} from '@/base/api/interface/user';

export interface IUserDetail {
    // 未完成订单数量
    no_completed_count?: number;
    // 完成订单数量
    completed_count?: number;
    // 是否有 SAAS 权限
    business_exists?: boolean;
    // 是否支持资金池
    visit_wallet?: boolean;
    // 用户身份 5: 游客;  10: TP;  20: 大搜代理商;  30: 5天试用; 40: 美国买家数据库试用;
    user_type?: USER_TYPE_ENUM;
    // 10: uc 账号; 20: passport 账号;
    login_type?: 10 | 20;
    // 用户名
    uname?: string;
    // 是否是子账号
    is_sub_account?: boolean;
    // 试用权益状态:
    profit_status: TRIAL_STATUS_ENUM;
}
export interface IUserState {
    isCompletedForUserInfoRequest?: boolean;
    isLogin?: boolean;
    uname?: string;
    detail?: IUserDetail;
    loginType?: 'uc' | 'passport';
}

enum LOGIN_TYPE_ENUM {
    uc = '10',
    passport = '20',
};

const LOGIN_TYPE_ALIAS = {
    [LOGIN_TYPE_ENUM.uc]: 'uc',
    [LOGIN_TYPE_ENUM.passport]: 'passport',
};

export const storeUserKey: InjectionKey<Store<IUserState>> = Symbol('storeUserKey');

export default () => ({
    namespaced: true,
    state: () => ({
        isCompletedForUserInfoRequest: false,
        isLogin: false,
        loginType: '',
        uname: '',
    }),
    mutations: {
        setUserInfoRequestStatus(state, payload) {
            state.isCompletedForUserInfoRequest = payload;
        },
        setLoginInfo(state, payload) {
            state.isLogin = payload.isLogin;
            state.uname = payload.uname;
            state.loginType = payload.loginType;
        },
        setUserDetail(state, payload) {
            state.detail = payload;
        },
        setLoginOut(state) {
            state.isLogin = false;
        },
    },
    actions: {
        getUserDetail: async ({commit, state, rootState}) => {
            try {
                const resp = await getUserDetail(rootState.headers);

                const {uname, login_type} = resp.data;

                const userInfo = {
                    isLogin: typeof login_type !== undefined,
                    uname: uname,
                    loginType: LOGIN_TYPE_ALIAS[login_type],
                };

                if (userInfo.isLogin) {
                    commit('setLoginInfo', userInfo);
                }

                commit('setUserDetail', resp.data);

                // 标识userinfo 接口已返回结果
                commit('setUserInfoRequestStatus', true);
            }
            catch (error: any) {
                if (error.data?.errno === 401) {
                    commit('setLoginInfo', {});
                }
                // 标识userinfo 接口已返回结果
                commit('setUserInfoRequestStatus', true);
                // console.error('error', 'getuser error', error);
                return {};
            }
        },
    },
    getters: {},
}) as Module<IUserState, IRootState>;
