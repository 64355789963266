import Apm from '@baidu/aff-web-apm';

const APP_TYPE = 4;

export function initAffWebApm() {
    window.localStorage.setItem('apmSwitch', 'true');
    // 初始化 apm
    window.apm = Apm.instance;
    window.APM = Apm.instance;
    window.apm.setAppType(APP_TYPE);
    window.apm.setDebugMode(false);
}
