import {IS_NODE} from '.';

export type CacheType = 'localStorage' | 'sessionStorage' | 'memory';

const NodeStorage = {
    getItem: () => null,
    setItem: () => null,
    removeItem: () => true,
    clear: () => true,
    length: 0,
};

/**
 * super cache；localStorage | sessionStorage | memory 适配器
 */
export class TSuperCache<Value extends any> {
    keys: string[];

    private readonly localStorage: Storage | typeof NodeStorage;
    private readonly sessionStorage: Storage | typeof NodeStorage;
    private readonly cache: Storage | Map<string, Value>;

    constructor() {
        this.localStorage = IS_NODE ? NodeStorage : localStorage;
        this.sessionStorage = IS_NODE ? NodeStorage : sessionStorage;
        this.cache = new Map<string, Value>();
        this.keys = [];
    }

    getItem(key: string, type: CacheType = 'memory') {
        if (type === 'localStorage') {
            return JSON.parse(this.localStorage.getItem(key) || 'null');
        }
        else if (type === 'sessionStorage') {
            return JSON.parse(this.sessionStorage.getItem(key) || 'null');
        }

        return this.cache.get(key);
    }

    setItem(key: string, value: Value, type: CacheType = 'memory') {
        if (type === 'localStorage') {
            return this.localStorage.setItem(key, JSON.stringify(value));
        }
        else if (type === 'sessionStorage') {
            return this.sessionStorage.setItem(key, JSON.stringify(value));
        }

        // cache 可能会覆盖 set、keys 不能重复
        if (!this.keys.includes(key)) {
            this.keys.push(key);
        }

        return this.cache.set(key, value);
    }

    getSize(type: CacheType = 'memory') {
        if (type === 'localStorage') {
            return this.localStorage.length;
        }
        else if (type === 'sessionStorage') {
            return this.sessionStorage.length;
        }

        return this.cache.size();
    }

    clear(type: CacheType = 'memory') {
        this.keys.forEach(key => {
            if (type === 'localStorage') {
                if (this.localStorage.getItem(key)) {
                    this.localStorage.removeItem(key);
                }
            }
            else if (type === 'sessionStorage') {
                if (this.sessionStorage.getItem(key)) {
                    this.sessionStorage.removeItem(key);
                }
            }

            this.cache.clear();
            // if (this.cache.has(key)) {
            //     this.cache.delete(key);
            // }
        });
    }

    removeItem(key: string, type: CacheType = 'memory') {
        if (type === 'localStorage') {
            if (this.localStorage.getItem(key)) {
                this.localStorage.removeItem(key);
            }
        }
        else if (type === 'sessionStorage') {
            if (this.sessionStorage.getItem(key)) {
                this.sessionStorage.removeItem(key);
            }
        }
        this.keys.splice(this.keys.findIndex(i => i === key), 1);
        this.cache.delete(key);
    }
}
