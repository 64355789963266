/**
 * @file 日志工具
 * @description 日志工具
 * @author yuanxiaoshuai@baidu.com
 */
import {createLogger, format, transports} from 'winston';
// import fileStreamRotato from 'file-stream-rotator';

const logger = createLogger({
    format: format.combine(
        format.timestamp({
            format: 'YYYY-MM-DD HH:mm:ss,SSS',
        }),
        //
        // The simple format outputs
        // `${level}: ${message} ${[Object with everything else]}`
        //
        format.simple(),
        //
        // Alternatively you could use this custom printf format if you
        // want to control where the timestamp comes in your final message.
        // Try replacing `format.simple()` above with this:
        //
        format.printf(info => `${info.timestamp} ${info.level.toUpperCase()} ${info.message}`)
    ),
    transports: [new transports.Console()],
});

export {logger};
