import {Module} from 'vuex';
import {IRootState} from '.';
import type {IGetNewsListRes} from '@/base/api/interface/news';
import type {IGetSubjectInfo, IGetSubjectMutiplat, IGetSubjectMergeList} from '@/base/api/interface/subject';


export interface ISubjectState {
    subjectInfo?: IGetSubjectInfo;
    subjectNewsList?: IGetNewsListRes;
    subjectMutiplat?: IGetSubjectMutiplat;
    subjectMergeList?: IGetSubjectMergeList;
}

export enum SubjectMutationEnum {
    SET_SUBJECT_INFO_DATA = 'SET_SUBJECT_INFO_DATA',
    SET_SUBJECT_NEWS_LIST_DATA = 'SET_SUBJECT_NEWS_LIST_DATA',
    SET_SUBJECT_MUTIPLAT = 'SET_SUBJECT_MUTIPLAT',
    SET_SUBJECT_MERGET_LIST = 'SET_SUBJECT_MERGET_LIST'
}

function ceateSubjectSteateModule(): Module<ISubjectState, IRootState> {
    return {
        namespaced: true,
        state: {
            subjectInfo: undefined,
            subjectNewsList: undefined,
            subjectMutiplat: undefined,
            subjectMergeList: undefined,
        },
        mutations: {
            [SubjectMutationEnum.SET_SUBJECT_NEWS_LIST_DATA](state, payload: ISubjectState['subjectNewsList']) {
                state.subjectNewsList = payload;
            },
            [SubjectMutationEnum.SET_SUBJECT_INFO_DATA](state, payload: ISubjectState['subjectInfo']) {
                state.subjectInfo = payload;
            },
            [SubjectMutationEnum.SET_SUBJECT_MUTIPLAT](state, payload: ISubjectState['subjectMutiplat']) {
                state.subjectMutiplat = payload;
            },
            [SubjectMutationEnum.SET_SUBJECT_MERGET_LIST](state, payload: ISubjectState['subjectMergeList']) {
                state.subjectMergeList = payload;
            },
        },
    };
}

export default ceateSubjectSteateModule;
