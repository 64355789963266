import {createMemoryHistory, createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {IS_NODE, IS_SERVER} from '@/base/utils';
import {setLog} from '@/base/log/sclog';
import useApmRouter from '@/base/hooks/useApm/useApmRouter';


/**
 *  webpackChunkName 不能相同、否则会导致 CSS 会被打包到一起
 *  直接访问某个路由时、无法直接加载到此路由的CSS文件、造成页面闪烁
 */
const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home/Home.vue');
const News = () => import(/* webpackChunkName: "News" */ '@/views/News/Index.vue');
/** ----- 合作伙伴下线 start 20240130 pm: liyingzhou ----- */
// const Partner = () => import(/* webpackChunkName: "Partner" */ '@/views/Partner/index.vue');
// const PartnerIndex = () => import(/* webpackChunkName: "PartnerIndex" */ '@/views/Partner/PartnerIndex/index.vue');
// const PartnerSearch = () => import(/* webpackChunkName: "PartnerSearch" */
//     '@/views/Partner/PartnerSearchDetail/index.vue');
/** ----- 合作伙伴下线 end 20240130 pm: liyingzhou ----- */
const NewsDetail = () => import(/* webpackChunkName: "NewsDetail" */ '@/views/News/Detail/Index.vue');
const CommodityList = () => import(/* webpackChunkName: "CommodityList" */ '@/views/Commodity/List.vue');
const Solution = () => import(/* webpackChunkName: "Solution" */ '@/views/Solution/Index.vue');
// const SolutionCommon = () => import(
//     /* webpackChunkName: "SolutionCommon" */ '@/views/Solution/SolutionCommon/index.vue');
const SolutionSenior = () => import(
    /* webpackChunkName: "SolutionSenior" */ '@/views/Solution/SolutionSenior/index.vue');
const SolutionStation = () => import(
    /* webpackChunkName: "SolutionStation" */ '@/views/Solution/SolutionStation/index.vue');
const SolutionDetail = () => import(
    /* webpackChunkName: "SolutionDetail" */ '@/views/Solution/SolutionDetail/Index.vue');
const MergeList = () => import(/* webpackChunkName: "MergeList" */ '@/views/MergeList/index.vue');
const SearchList = () => import(/* webpackChunkName: "SearchList" */ '@/views/SearchList/index.vue');
const Subject = () => import(/* webpackChunkName: "Subject" */ '@/views/Subject/index.vue');
const SubjectDetail = () => import(/* webpackChunkName: "SubjectDetail" */ '@/views/SubjectDetail/index.vue');
const LabelSearchList = () => import(/* webpackChunkName: "LabelSearchList" */ '@/views/LabelSearchList/index.vue');
const Customer = () => import(/* webpackChunkName: "Customer" */ '@/views/Customer/index.vue');
const UsCustomsData = () => import(/* webpackChunkName: "UsCustomsData" */ '@/views/Customer/UsCustomsData/index.vue');
const UsCustomsDataDetail = () =>
    import(/* webpackChunkName: "UsCustomsDataDetail" */ '@/views/Customer/UsCustomsData/Detail/index.vue');
const Error404 = () => import(/* webpackChunkName: "Error404" */ '@/views/components/Error/Error404.vue');

/**
 * @property meta 对象内参数说明:
 * channel: 对应的是头部住 导航 上的菜单名, 用作高亮
 * pageName: 具体页面的标识，现在在 埋点上报时有用到
 *
 * TODO ... 后续 meta 内新增属性时在此添加
 */

// 注意：组件的名称必须与上方注释的 webpackChunkName 一致，否则会造成页面预渲染后样式闪烁！
const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            channel: 'home',
            pageName: 'home',
        },
    },
    {
        path: '/news',
        name: 'News',
        component: News,
        meta: {
            channel: 'news',
            pageName: 'news',
        },
    },
    {
        path: '/solution',
        name: 'Solution',
        component: Solution,
        meta: {
            channel: 'solution',
            pageName: 'solution',
            // isHeaderFontColorBlack: true,
        },
        redirect: {
            name: 'SolutionCommon',
        },
        children: [
            {
                path: 'detail/:spuId/:skuId?',
                name: 'SolutionDetail',
                component: SolutionDetail,
                meta: {
                    withAuth: true,
                    isHeaderFontColorBlack: true,
                    channel: 'solution',
                    bodyBg: '#ECEEF3',
                    noFooter: true,
                },
            },
            // {
            //     path: 'common',
            //     name: 'SolutionCommon',
            //     component: SolutionCommon,
            //     meta: {
            //         channel: 'solution',
            //         isWhiteBgHeader: false,
            //         isHeaderPadding: false,
            //         // isHeaderFontColorBlack: true,
            //     },
            // },
            {
                path: 'senior',
                name: 'SolutionSenior',
                component: SolutionSenior,
                meta: {
                    channel: 'solution',
                    isWhiteBgHeader: false,
                    isHeaderPadding: false,
                },
            },
            {
                path: 'station',
                name: 'SolutionStation',
                component: SolutionStation,
                meta: {
                    channel: 'solution',
                    isWhiteBgHeader: false,
                    isHeaderPadding: false,
                },
            },
        ],
    },
    // 模块下线 - 20240130 pm: liyingzhou
    // {
    //     path: '/partner',
    //     name: 'Partner',
    //     component: Partner,
    //     meta: {
    //         channel: 'partner',
    //         pageName: 'partner',
    //         isBlueLog: true,
    //         isHeaderFontColorBlack: true,
    //     },
    //     children: [
    //         {
    //             path: '',
    //             name: 'PartnerIndex',
    //             component: PartnerIndex,
    //             meta: {
    //                 channel: 'partner',
    //                 pageName: 'partner',
    //                 isBlueLog: true,
    //                 isHeaderFontColorBlack: true,
    //             },
    //         },
    //         {
    //             path: 'search',
    //             name: 'PartnerSearch',
    //             component: PartnerSearch,
    //             meta: {
    //                 channel: 'partner',
    //                 pageName: 'partnerSearch',
    //                 isHeaderFontColorBlack: true,
    //             },
    //         },
    //     ],
    // },
    {
        path: '/news/detail/:id',
        name: 'NewsDetail',
        component: NewsDetail,
        meta: {
            channel: 'news',
            pageName: 'news-detail',
            adaptWise: true,
            // 是否阻止默认的页面 pv 统计
            isBlockPvLog: true,
        },
    },
    // 与 '/news/detail/:id' 都是资讯详情页，区别在于：此路由在资讯列表页中不会有此路由的链接，暂服务于 seo，属于实验性的
    {
        path: '/contents/detail/:id',
        name: 'ContentsDetail',
        component: NewsDetail,
        meta: {
            channel: 'news',
            pageName: 'contents-detail',
            adaptWise: true,
            // 是否阻止默认的页面 pv 统计
            isBlockPvLog: true,
        },
    },
    // seo 使用
    {
        path: '/commodity/list/:page_id',
        name: 'CommodityPage',
        component: CommodityList,
        meta: {
            channel: 'news',
            pageName: 'commodity-list',
            adaptWise: true,
            chunkName: 'CommodityList',
            hideLogin: true,
        },
    },
    {
        path: '/commodity/list',
        name: 'CommodityList',
        component: CommodityList,
        meta: {
            channel: 'news',
            pageName: 'commodity-list',
            adaptWise: true,
            chunkName: 'CommodityList',
            hideLogin: true,
        },
    },
    {
        path: '/mergelist',
        name: 'MergeList',
        component: MergeList,
        meta: {
            channel: 'mergeList',
            pageName: 'mergeList',
        },
    },
    {
        path: '/searchlist/:keyword',
        name: 'SearchList',
        component: SearchList,
        meta: {
            channel: 'searchList',
            pageName: 'searchList',
        },
    },
    {
        path: '/subject/detail/:keyword',
        name: 'SubjectDetail',
        component: SubjectDetail,
        meta: {
            channel: 'subject',
            pageName: 'subjectdetail',
        },
    },
    {
        path: '/subject',
        name: 'Subject',
        component: Subject,
        meta: {
            channel: 'subject',
            pageName: 'subject',
        },
    },
    {
        path: '/labelsearchlist/:keyword',
        name: 'LabelSearchList',
        component: LabelSearchList,
        meta: {
            channel: 'labelsearchlist',
            pageName: 'labelsearchlist',
        },
    },
    {
        path: '/agrt/:type/:lang?',
        name: 'agrt',
        component: () => import(/* webpackChunkName: "Agrt" */ '@/views/Assets/Agreement.vue'),
        meta: {
            channel: 'agrt',
            pageName: 'agrt',
        },
    },
    {
        path: '/customer',
        name: 'Customer',
        component: Customer,
        redirect: () => {
            return {
                name: 'UsCustomsData',
            };

        },
        meta: {
            channel: 'Customer',
            pageName: 'Customer',
        },
        children: [
            // 美国买家数据库
            {
                path: 'uscustomsdata',
                name: 'UsCustomsData',
                component: UsCustomsData,
                meta: {
                    pageName: 'UsCustomsData',
                    channel: 'UsCustomsData',
                },
            },
            {
                path: 'uscustomsdata/:id',
                name: 'UsCustomsDataDetail',
                component: UsCustomsDataDetail,
                meta: {
                    pageName: 'UsCustomsData',
                    channel: 'UsCustomsData',
                },
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error404',
        component: Error404,
        meta: {
            channel: 'error404',
            pageName: 'error404',
        },
    },
];

export default function initRouter() {
    const history = IS_NODE ? createMemoryHistory() : createWebHistory();

    const router = createRouter({
        history,
        routes,
        scrollBehavior: (to, from) => {
            if (to.hash) {
                return {
                    el: to.hash,
                    behavior: 'smooth',
                };
            }

            if (to.path !== from.path) {
                return {
                    top: 0,
                };
            }
        },
    });

    // Apm 对路由行为进行性能监控
    !IS_NODE && useApmRouter(router);

    router.beforeEach(async (to, _from, next) => {
        // 设置body背景色
        if (!IS_SERVER && _from.meta.bodyBg !== to.meta.bodyBg) {
            document.body.style.background = to.meta.bodyBg as string || '#fff';
        }

        // 神策打点
        setLog({
            prepage: _from.name?.toString().toLocaleLowerCase() || '',
            page: to.name?.toString().toLocaleLowerCase(),
        });

        next();
    });

    return router;
}
