import {ISendParams} from '../interface/log';

// interface ILogJson {
//     [key: string]: ISendParams;
// };

export const logJson = {
    // test 首页展示pv
    trade_home_pv: {
        'act': 'pv',
    },
};

export type ILogSendParamsMap = Record<keyof typeof logJson, ISendParams>;