/** BMS server 接口 */
const BASE_API = process.env.VUE_APP_BASE_API || '/openapi/portal/service';
const COMMON_API = process.env.VUE_APP_COMMON_API || '/openapi/com/service';
const MALL_API = process.env.VUE_APP_MALL_API || '/waimao/mall/open/service';
const BOS_FINANCE_API = process.env.VUE_APP_BOS_FINANCE_API || '/b2b-waimao-finance';
// const WAIMAO_GUEST_API = process.env.VUE_APP_WAIMAO_GUEST_API || '/waimao/guest';

const WAIMAO_GUEST_MALL_API = process.env.VUE_APP_WAIMAO_GUEST_MALL_API || '/waimao/guest/mall';

const WAIMAO_GUEST_AGENT_API = process.env.VUE_APP_WAIMAO_GUEST_AGENT_API || '/waimao/guest/agent';

const WAIMAO_GUEST_APPROVE_API = process.env.VUE_APP_WAIMAO_GUEST_APPROVE_API || '/waimao/guest/approve';

const WAIMAO_GUEST_PRODUCT_API = process.env.VUE_APP_WAIMAO_GUEST_PRODUCT_API || '/waimao/guest/product';

const WAIMAO_GUEST_FINANCE_API = process.env.VUE_APP_WAIMAO_GUEST_FINANCE_API || '/waimao/guest/finance';

const WAIMAO_GUEST_MARKET_API = process.env.VUE_APP_WAIMAO_GUEST_MARKET_API || '/waimao/guest/market';

const WAIMAO_OPEN_API = process.env.VUE_APP_WAIMAO_OPEN_API || '/waimao/openapi';

// 代理到爱采购的接口前缀
const B2B_OPEN_API = process.env.VUE_APP_B2B_OPEN_API || '/openapi/portal/service/match';

// aff server 转发规则 背景，后端只迁移了部分接口，这些是他们在网关层的转发，我们node需要一致
const OPEN_API_PORTAL = '/openapi/portal';
const OPEN_API_PORTAL_SERVICE_INDEX_CAPTCHA = '/openapi/portal/service/index/captcha';
const OPEN_API_PORTAL_SERVICE_INDEX_LEADS = '/openapi/portal/service/index/leads';
const AMIS = '/amis';
const AMIS_PORTAL_BANNER = '/amis/portal/banner';

/** 需要代理 Node 服务（登录校验）接口 path  */
const NODE_PROXY_API = process.env.VUE_APP_PROXY_API || '/nodeapi';

const BMS_PORT = process.env.BMS_SERVICE_PORT_HTTP || 2333;

/** 代理相关 start */
const BMS_HOST = process.env.BACKEND_URL || `http://bms:${BMS_PORT}`;

// 代理的 target、aff vip 需要添加 x-service-name
const proxyTargetByBackendHost = {
    hexinAuth: 'http://hexin17.bcc-gzhxy.baidu.com:8050',
    // chuhaiyitest: 'https://chuhaiyi-test.baidu-int.com',
    // chuhaiyi: 'https://chuhaiyi-test.baidu-int.com',
    dev: 'http://10.24.9.165:8980',

    // aff vip
    test: 'http://10.21.169.6:80',
    preonline: 'http://10.199.5.111',
    online: 'http://sz-cce-http.gw-k8s-aff.baidu-int.com',
};

const TRADE_NODE_PORT = `http://trade-node:${process.env.TRADE_NODE_SERVICE_PORT_HTTP || 2333}`;

const SERVER_K8S_HOST = `http://chuhaiyi-server:${process.env.CHUHAIYI_SERVER_SERVICE_PORT_HTTP || 2333}`;

const getWaimaoGuestApiProxy = url => {
    const waimaoUrl = url.replace('/guest', '');

    const waimaoHost = waimaoUrl.replace('/waimao/', '/waimao-');

    const waimaoService = waimaoUrl.replace('/', '_').toLocaleUpperCase();

    return `http://${waimaoHost}:${process.env[waimaoService] || 2333}`;
};


const WAIMAO_DEV_PORTS = {
    bms: {
        main: 8010,
        node: 8011,
    },
    saas: {
        main: 8020,
        node: 8021,
    },
    site: {
        main: 8030,
    },
    site_bd: {
        main: 8031,
    },
    webdesign: {
        main: 8040,
    },
    node: {
        main: 8050,
    },
    eco: {
        main: 8060,
    },
};

const CLIENT_COMPLIER_PORT = process.env.CLIENT_COMPLIER_PORT
    ? Number(process.env.CLIENT_COMPLIER_PORT) : WAIMAO_DEV_PORTS.bms.main;

const STATIC_BOS_URL = {
    development: '/',
    qa: process.env.WEBPACK_TARGET === 'node' ? '/' : 'https://aiff.cdn.bcebos.com/trade-bms/qa/',
    preonline: process.env.WEBPACK_TARGET === 'node' ? '/' : 'https://fe-aff-preonline.cdn.bcebos.com/trade-bms/preonline/',
    online: process.env.WEBPACK_TARGET === 'node' ? '/' : 'https://fe-aff.cdn.bcebos.com/trade-bms/online/',
    production: process.env.WEBPACK_TARGET === 'node' ? '/' : 'https://fe-aff.cdn.bcebos.com/trade-bms/online/',
};

const publicPath = STATIC_BOS_URL[process.env.APP_MODE || 'development'];

const waimaoProxy = {
    [WAIMAO_GUEST_AGENT_API]: {
        target: getWaimaoGuestApiProxy(WAIMAO_GUEST_AGENT_API),
        logLevel: 'debug',
        changeOrigin: true,
    },
    [WAIMAO_GUEST_APPROVE_API]: {
        target: getWaimaoGuestApiProxy(WAIMAO_GUEST_APPROVE_API),
        logLevel: 'debug',
        changeOrigin: true,
    },
    [WAIMAO_GUEST_PRODUCT_API]: {
        target: getWaimaoGuestApiProxy(WAIMAO_GUEST_PRODUCT_API),
        logLevel: 'debug',
        changeOrigin: true,
    },
    [WAIMAO_GUEST_FINANCE_API]: {
        target: getWaimaoGuestApiProxy(WAIMAO_GUEST_FINANCE_API),
        logLevel: 'debug',
        changeOrigin: true,
    },
    [WAIMAO_GUEST_MARKET_API]: {
        target: getWaimaoGuestApiProxy(WAIMAO_GUEST_MARKET_API),
        logLevel: 'debug',
        changeOrigin: true,
    },
    [WAIMAO_OPEN_API]: {
        target: 'http://local.baidu.com:8010',
        changeOrigin: true,
        logLevel: 'debug',
        headers: {
            'x-service-name': 'chuhaiyi-server',
        },
    },
};

const portalProxy = {
    [OPEN_API_PORTAL_SERVICE_INDEX_CAPTCHA]: {
        target: BMS_HOST,
        logLevel: 'debug',
    },
    [OPEN_API_PORTAL_SERVICE_INDEX_LEADS]: {
        target: BMS_HOST,
        logLevel: 'debug',
    },
    // 爱采购接口
    [B2B_OPEN_API]: {
        target: SERVER_K8S_HOST,
        logLevel: 'debug',
    },
    [OPEN_API_PORTAL]: {
        target: SERVER_K8S_HOST,
        logLevel: 'debug',
    },
    [AMIS_PORTAL_BANNER]: {
        target: SERVER_K8S_HOST,
        logLevel: 'debug',
    },
    [AMIS]: {
        target: BMS_HOST,
        logLevel: 'debug',
    },
};

const bmsProxy = {
    [COMMON_API]: {
        target: BMS_HOST,
        logLevel: 'debug',
    },
};

const nodeProxy = {
    [NODE_PROXY_API]: {
        // 独立的 Node proxy server
        target: TRADE_NODE_PORT,
        changeOrigin: true,
        logLevel: 'debug',
        headers: {
            'x-service-name': 'trade-node',
        },
    },
};

const bosProxy = {
    [BOS_FINANCE_API]: {
        target: 'http://b2b-waimao-finance.cdn.bcebos.com',
        logLevel: 'debug',
        changeOrigin: true,
        pathRewrite: {
            '/b2b-waimao-finance': '',
        },
        headers: {
            Referer: 'http://b2b-waimao-finance.cdn.bcebos.com/',
        },
    },
};

const config = {
    development: {
        publicPath,
        clientPort: CLIENT_COMPLIER_PORT,
        ssrPort: WAIMAO_DEV_PORTS.bms.node,
        HOST: 'http://zhuxiaoyu01.bcc-bdbl.baidu.com:8980',
        proxy: {
            // 爱采购接口
            [B2B_OPEN_API]: {
                target: 'http://wudongju.bcc-szwg.baidu.com:8980',
                changeOrigin: true,
                logLevel: 'debug',
                headers: {
                    'x-service-name': 'chuhaiyi-server',
                },
            },
            [OPEN_API_PORTAL_SERVICE_INDEX_CAPTCHA]: {
                target: proxyTargetByBackendHost.test,
                logLevel: 'debug',
                changeOrigin: true,
                headers: {
                    'x-service-name': 'bms',
                },
            },
            [OPEN_API_PORTAL_SERVICE_INDEX_LEADS]: {
                target: proxyTargetByBackendHost.test,
                // 开发环境使用本地 mock 时使用此代理地址
                // target: 'http://local.baidu.com:8010',
                logLevel: 'debug',
                changeOrigin: true,
                headers: {
                    'x-service-name': 'bms',
                },
            },
            [OPEN_API_PORTAL]: {
                target: proxyTargetByBackendHost.test,
                // 开发环境使用本地 mock 时使用此代理地址
                // target: 'http://local.baidu.com:8010',
                // target: 'http://10.24.9.165:8980',
                changeOrigin: true,
                logLevel: 'debug',
                headers: {
                    'x-service-name': 'chuhaiyi-server',
                },
            },
            [COMMON_API]: {
                target: proxyTargetByBackendHost.test,
                logLevel: 'debug',
                changeOrigin: true,
                headers: {
                    'x-service-name': 'bms',
                },
            },
            [NODE_PROXY_API]: {
                // 转发规则见 http://wiki.baidu.com/pages/viewpage.action?pageId=1770667619#CCE%E7%94%A8%E6%88%B7%E6%89%8B%E5%86%8C-%E4%BA%94%E3%80%81%E8%AE%BF%E9%97%AEhttp%E6%96%B9%E5%BC%8F
                // 独立的 Node proxy server
                // target: proxyTargetByBackendHost.test,
                // 开发环境使用本地 mock 时使用此代理地址
                target: 'http://local.baidu.com:8010',
                // target: proxyTargetByBackendHost.hexinAuth,

                // target: `http://hexin17.bcc-gzhxy.baidu.com:${WAIMAO_DEV_PORTS.node.main}`,
                changeOrigin: true,
                logLevel: 'debug',
                headers: {
                    'x-service-name': 'trade-node',
                    'proxy_host': 'local.baidu.com',
                    // ['proxy_target']: 'http://172.24.148.171:8090',
                },
            },
            [WAIMAO_GUEST_AGENT_API]: {
                target: proxyTargetByBackendHost.test,
                logLevel: 'debug',
                changeOrigin: true,
                pathRewrite: {
                    '/waimao/guest': '/waimao/guest',
                },
                headers: {
                    'x-service-name': 'waimao-agent',
                },
            },
            [WAIMAO_GUEST_MALL_API]: {
                target: proxyTargetByBackendHost.test,
                logLevel: 'debug',
                changeOrigin: true,
                pathRewrite: {
                    '/waimao/guest': '/waimao/guest',
                },
                headers: {
                    'x-service-name': 'waimao-mall',
                },
            },
            [WAIMAO_OPEN_API]: {
                target: 'http://10.12.170.19',
                changeOrigin: true,
                logLevel: 'debug',
                headers: {
                    'x-service-name': 'chuhaiyi-server',
                },
            },
            ...bosProxy,
        },
    },
    qa: {
        clientPort: CLIENT_COMPLIER_PORT,
        ssrPort: process.env.NODE_PORT || WAIMAO_DEV_PORTS.bms.node,
        publicPath,
        HOST: SERVER_K8S_HOST,
        proxy: {
            ...bosProxy,
            ...bmsProxy,
            ...nodeProxy,
            ...portalProxy,
            ...waimaoProxy,
            [WAIMAO_OPEN_API]: {
                target: 'http://10.12.170.19',
                changeOrigin: true,
                logLevel: 'debug',
                headers: {
                    'x-service-name': 'chuhaiyi-server',
                },
            },
        },
    },
    preonline: {
        clientPort: CLIENT_COMPLIER_PORT,
        ssrPort: process.env.NODE_PORT || WAIMAO_DEV_PORTS.bms.node,
        publicPath,
        HOST: SERVER_K8S_HOST,
        proxy: {
            ...bosProxy,
            ...bmsProxy,
            ...nodeProxy,
            ...portalProxy,
            ...waimaoProxy,
            [WAIMAO_OPEN_API]: {
                target: 'http://10.12.170.14',
                changeOrigin: true,
                logLevel: 'debug',
                headers: {
                    'x-service-name': 'chuhaiyi-server',
                },
            },
        },
    },
    online: {
        clientPort: CLIENT_COMPLIER_PORT,
        ssrPort: process.env.NODE_PORT || WAIMAO_DEV_PORTS.bms.node,
        publicPath,
        HOST: SERVER_K8S_HOST,
        proxy: {
            ...bosProxy,
            ...bmsProxy,
            ...nodeProxy,
            ...portalProxy,
            ...waimaoProxy,
            [WAIMAO_OPEN_API]: {
                target: 'http://10.12.170.15',
                changeOrigin: true,
                logLevel: 'debug',
                headers: {
                    'x-service-name': 'chuhaiyi-server',
                },
            },
        },
    },
    production: {
        clientPort: CLIENT_COMPLIER_PORT,
        ssrPort: process.env.NODE_PORT || WAIMAO_DEV_PORTS.bms.node,
        publicPath,
        HOST: SERVER_K8S_HOST,
        proxy: {
            ...bosProxy,
            ...bmsProxy,
            ...nodeProxy,
            ...portalProxy,
            ...waimaoProxy,
            [WAIMAO_OPEN_API]: {
                target: 'http://10.12.170.15',
                changeOrigin: true,
                logLevel: 'debug',
                headers: {
                    'x-service-name': 'chuhaiyi-server',
                },
            },
        },
    },
};

const getConfig = (env = process.env.APP_MODE) => {
    return config[env] || config.online;
};

// export {config, getConfig, CLIENT_COMPLIER_PORT, BASE_API, COMMON_API, PROXY_API, MALL_API, BOS_FINANCE_API};

module.exports = {
    config,
    getConfig,
    CLIENT_COMPLIER_PORT,
    BASE_API,
    COMMON_API,
    PROXY_API: NODE_PROXY_API,
    MALL_API,
    BOS_FINANCE_API,
};
