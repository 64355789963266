/**
 * @file log 埋点上报方法
 * @author liuqifeng01@baidu.com
 *
 * @exports createLog 初始化创建 log 实例
 * @exports useLog 使用 log 方法
 *
 * @todo logid 的生成及上报方案
 */
import type {App} from 'vue';
import Log from './log-sdk';
import {ILogConstructorParams, ILog, ISendParams} from './interface/log';
import {ILogSendParamsMap} from '@/base/log/json';

export interface ICustomLog {
    send: (params: keyof ILogSendParamsMap, mergeParams?: ISendParams) => void;
    install: (app: App) => void;
}

class CustomLog implements ICustomLog {
    static customLogInstance: ICustomLog | null = null;
    logSendParamsMap: ILogSendParamsMap;
    private readonly logInstance: Log;
    constructor(params: ILogConstructorParams, logSendParamsMap: ILogSendParamsMap) {
        this.logInstance = new Log(params);
        this.logSendParamsMap = logSendParamsMap;
    }

    static getInstance(params: ILogConstructorParams, logSendParamsMap: ILogSendParamsMap) {
        const instance = CustomLog.customLogInstance || new CustomLog(params, logSendParamsMap);
        return CustomLog.customLogInstance = instance as ICustomLog;
    }

    send(params: keyof ILogSendParamsMap | ISendParams, mergeParams?: ISendParams) {
        let sendParams: ISendParams = {} as ISendParams;
        if (typeof params === 'string') {
            sendParams = this.logSendParamsMap[params] || sendParams;
        }
        else if (Reflect.toString.call(params) === '[object Object]') {
            sendParams = params as ISendParams;
        }
        else {
            throw new Error('埋点上报参数格式错误');
        }
        sendParams = Object.assign({}, sendParams, mergeParams);
        this.logInstance.send(sendParams);
    }

    install(app: App) {
        // @ts-ignore
        app.config.globalProperties.$log = Log.logInstance as ILog;
    }
};


export const createLog = <T extends any>(params: ILogConstructorParams, logSendParamsMap: T): ICustomLog => {
    const defaultParams = {
        isDev: false,
    };
    params = Object.assign({}, defaultParams, params);
    return CustomLog.getInstance(params, logSendParamsMap as any);
};

export const useLog = () => {
    if (!CustomLog.customLogInstance) {
        throw new Error('Please call the "createLog" method first');
    }
    return CustomLog.customLogInstance as ICustomLog;
};
