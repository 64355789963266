import {CacheType, TSuperCache} from './cache';

interface ILinkNode {
    key: string;
    value: any;
    next: LinkNode | null;
    prev: LinkNode | null;
}

class LinkNode implements ILinkNode {
    key: ILinkNode['key'];
    value: ILinkNode['value'];
    next: ILinkNode['next'];
    prev: ILinkNode['prev'];

    constructor(key: string, value: any, next = null, prev = null) {
        this.key = key;
        this.value = value;
        this.next = next;
        this.prev = prev;
    }
}

/**
 * lru cache
 * 参考：https://medium.com/dsinjs/implementing-lru-cache-in-javascript-94ba6755cda9
 */
export class LRUCache<Value extends unknown> {
    size: number;
    private readonly limit: number;
    private head: LinkNode | null;
    private tail: LinkNode | null;
    private cacheLink: {[k: string]: any};
    private readonly cache: TSuperCache<Value>;

    // set default limit of 10 if limit is not passed.
    constructor(limit = 10) {
        this.size = 0;
        this.limit = limit;
        this.head = null;
        this.tail = null;
        // 缓存
        this.cache = new TSuperCache();

        // lru 的链表
        this.cacheLink = {};
    }

    set(key: string, value: Value, type: CacheType = 'memory') {
        const existingNode = this.existingNode(key, type);
        if (existingNode && this.cacheLink[key]) {
            this.detach(this.cacheLink[key]);
            this.size--;
        }
        else if (this.size === this.limit) {
            if (this.tail) {
                console.info('info', '[lru removed]', this.tail.key, type);
                this.cache.removeItem(this.tail.key, type);
                this.detach(this.tail);
                this.size--;
            }
        }

        // Write to head of LinkedList
        if (!this.head) {
            this.head = this.tail = new LinkNode(key, value);
        }
        else {
            const node = new LinkNode(key, value, this.head as any);
            this.head.prev = node;
            this.head = node;
        }

        // update cacheMap with LinkedList key and Node reference
        this.cacheLink[key] = this.head;
        this.cache.setItem(key, value, type);
        this.size++;
    }

    get(key: string, type: CacheType = 'memory') {
        const existingNode = this.existingNode(key, type);
        if (existingNode) {
            const value = existingNode;
            // Make the node as new Head of LinkedList if not already
            if (this.head !== existingNode) {
                // write will automatically remove the node from it's position and make it a new head i.e most used
                this.set(key, value, type);
            }
            return value;
        }

        console.warn(`Item not available in cache for key ${key}`);
    }

    detach(node: LinkNode) {
        if (node.prev !== null) {
            node.prev.next = node.next;
        }
        else {
            this.head = node.next;
        }

        if (node.next !== null) {
            node.next.prev = node.prev;
        }
        else {
            this.tail = node.prev;
        }
    }

    clear() {
        this.head = null;
        this.tail = null;
        this.size = 0;
        this.cache.clear();
    }

    // Invokes the callback function with every node of the chain and the index of the node.
    forEach(fn: (node: LinkNode, counter: number) => void) {
        let node = this.head;
        let counter = 0;
        while (node) {
            fn(node, counter);
            node = node.next;
            counter++;
        }
    }

    // To iterate over LRU with a 'for...of' loop
    * [Symbol.iterator]() {
        let node = this.head;
        while (node) {
            yield node;
            node = node.next;
        }
    }

    private existingNode(key: string, type: CacheType) {
        return this.cache.getItem(key, type);
    }
}