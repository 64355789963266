import {createHead} from '@vueuse/head';
import {createApp, createSSRApp} from 'vue';
import {createPinia} from 'pinia';
import App from '../App.vue';
import {Ctx} from './entry.server';
import initStore, {storeInjectionKey} from '@/store';
import initRouter from '@/router';
import {IS_NODE} from '@/base/utils';
import {initAffWebApm} from '@/base/controller/affApm';
import {useEncryptFontPlugin} from '@/base/controller/encryptFont';
import ClientOnly from '@/components/ClientOnly/index.vue';

export default function (ctx?: Ctx) {
    const needHydration = !IS_NODE && !!window.__INITIAL_STATE__;
    const app = needHydration ? createSSRApp(App) : createApp(App);

    const pinia = createPinia();
    const store = initStore(ctx);
    const router = initRouter();
    const head = createHead();

    // aff apm
    if (!IS_NODE) {
        initAffWebApm();
        useEncryptFontPlugin(app);
    }

    router.beforeEach(async (to, _from, next) => {
        if (to.meta.withAuth) {
            await store.dispatch('user/getUserDetail');
        }
        next();
    });

    app.use(pinia).use(store, storeInjectionKey).use(router).use(head);

    app.component('client-only', ClientOnly);

    return {
        app,
        router,
        store,
        head,
        pinia,
    };
}
