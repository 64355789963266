import {defineStore} from 'pinia';

interface RootStore {
    // 是否有请求的数据错误， 是给 ssr 时判断是否进行页面的数据缓存使用，如果接口请求错误，就不缓存
    isDataErrorFromApiFetch: boolean;
};

export const useRootStore = defineStore('root', {
    state(): RootStore {
        return {
            isDataErrorFromApiFetch: false,
        };
    },
});
