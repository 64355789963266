import {Module} from 'vuex';
import {IRootState} from '.';
import type {IGetNewsListRes} from '@/base/api/interface/news';

export interface ILabelSearchListState {
    labelSearchListData?: IGetNewsListRes;
}

export enum LabelSearchListMutationEnum {
    SET_LABEL_SEARCH_LIST_DATA = 'SET_LABEL_SEARCH_LIST_DATA',
}

function createLabelSearchListModule(): Module<ILabelSearchListState, IRootState> {
    return {
        namespaced: true,
        state: {
            labelSearchListData: undefined,
        },
        mutations: {
            [LabelSearchListMutationEnum.SET_LABEL_SEARCH_LIST_DATA](state,
                payload: ILabelSearchListState['labelSearchListData']) {
                state.labelSearchListData = payload;
            },
        },
    };
}

export default createLabelSearchListModule;
