import {AxiosInstance, AxiosRequestConfig} from 'axios';
import {tryStopLogServer} from './useLogServer';
import {getApmInstance, getPageUrl, isApmReady} from './utils';

interface IConfig extends AxiosRequestConfig {
    optId?: string;
    reqId?: string;
}

function onResponse(config: IConfig) {
    if (config.optId && config.reqId && config.url) {
        const reqUrl = `${config.baseURL}${config.url}`;
        if (reqUrl) {
            getApmInstance().logRequest(config.optId, config.reqId, reqUrl, 1, getPageUrl());
        }
        const logUrl = `${config.baseURL}${config.url?.split('?')[0]}`;
        tryStopLogServer(getApmInstance().getOptId(), logUrl);
    }
}

/**
 * 为 axios 请求实例添加 apm 相关的拦截器，自动记录每个请求的请求链路与耗时
 * @description https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/HDeySYhuyv/O-RKEhHIxVKcH6#anchor-571466e0-b252-11ec-b34c-6bfd89749842
 * @param service
 */
export default function useApmRequest(service: AxiosInstance) {
    service.interceptors.request.use(
        (config: IConfig) => {
            if (!isApmReady()) {
                return;
            }
            // APM 记录请求 - 开始
            const optId = getApmInstance().getOptId();
            const reqId = getApmInstance().getNewId();
            config.optId = optId;
            config.reqId = reqId;
            const reqUrl = `${config.baseURL}${config.url}`;
            if (reqUrl) {
                getApmInstance().logRequest(optId, reqId, reqUrl, 0, getPageUrl());
            }

            return config;
        },
        error => Promise.reject(error)
    );

    service.interceptors.response.use(
        res => {
            // APM 记录请求 - 结束
            const config = res.config as IConfig;
            onResponse(config);
            return Promise.resolve(res);
        },
        error => {
            // APM 记录请求 - 结束
            const config = error.config as IConfig;
            onResponse(config);
            return Promise.reject(error);
        }
    );
}
