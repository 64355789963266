/**
 * @file 通用请求错误码及对应信息
 * @author liuqifeng01@baidu.com
 */

export const UN_KNOW_ERR_CODE = -1;

export const ERROR_MSG = {
    // 通用错误吗
    [UN_KNOW_ERR_CODE]: '未知错误',
    1000: '服务内部错误',
    2000: '参数绑定错误',
};

export const SERVER_ERROR_MSG = {
    [UN_KNOW_ERR_CODE]: '未知错误',
    // 通用错误吗
    400: '请求错误',
    401: '未授权 请登录',
    403: '拒绝访问',
    404: '请求地址出错',
    408: '请求超时',
    500: '网络或服务异常',
    501: '服务未实现',
    502: '网关错误',
    503: '服务不可用',
    504: '网关超时',
    505: 'HTTP版本不受支持',
};