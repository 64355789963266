import {HeadObject} from '@vueuse/head';
import {timeStampFormat} from '@/base/utils/time';

const DEFAULT_TITLE = '出海易';
const DEFAULT_KEYWORDS = [
    '出海易',
    '跨境',
    'b2b',
    '外贸',
    '海外市场',
    '跨境电商',
    '跨境电商平台',
    '电商平台',
    '跨境电商物流',
    '跨境资讯',
    '出海',
    '跨境出海',
    '出海资讯',
    '独立站',
    '广告投放',
    '海外营销',
    '海关数据',
    '海外服务',
    '海外线索',
    '海外商机',
    '海外数据',
];

/* eslint-disable max-len, vue/max-len */
const DEFAULT_DESCRIPTION = '出海易是百度旗下品牌，也是百度集团首个跨境电商SaaS业务线。出海易基于百度20多年大数据和人工智能技术沉淀，以及全球领先的B2B营销服务能力，为出海企业提供一站式数智外贸工作软件，包括外贸独立站建站、海外十大搜索引擎SEO、邮件营销、全球贸易数据、全球买家数据库、营销落地页等。';

const DEFAULT_DATETIME = `${timeStampFormat(new Date().valueOf(), 'yyyy-MM-dd')} 00:00:00'`;

interface ISeoBaseConfig {
    title?: string;
    keywords?: string[];
    description?: string;
    dateUpdate?: string;
    datePublished?: string;
    dateLatestReply?: string;
}

export const seoBaseConfig = {
    default: {
        title: DEFAULT_TITLE,
        keywords: DEFAULT_KEYWORDS,
        description: DEFAULT_DESCRIPTION,
    },
    home: {
        title: `${DEFAULT_TITLE} - 一站式数智外贸工作台｜全球买家数据库、海外营销自动化`,
        keywords: [
            '出海易',
            '出海',
            '外贸',
            '品牌',
            '数据',
            '推广',
            '营销',
            '跨境',
            '投放',
            'B2B',
            '跨境电商',
            '一站式',
            '跨境电商平台',
            '电商平台',
            '跨境电商物流',
            '跨境资讯',
            '出海',
            '跨境出海',
            '出海资讯',
        ],
        description: DEFAULT_DESCRIPTION,
    },
    news: {
        title: `${DEFAULT_TITLE} - 出海资讯`,
        keywords: [
            '资讯',
            '内容',
            '文章',
            '软文',
            '出海文章',
            '外贸软文',
            '品牌推广',
            '数据报告',
            '推广软文',
            '营销步骤',
            '营销文章',
            '跨境信息',
            '跨境电商资讯信息',
            '跨境电商平台',
            '电商平台',
            '跨境电商物流',
            '跨境资讯',
            '出海',
            '跨境出海',
            '出海资讯',
        ],
        description:
            '外贸网站建设步骤,跨境电商独立站如何建站,出口退税教程,跨境电商排名,跨境电商优化策略等,这些问题都可以在出海易找到答案，快点加入出海易吧！',
    },
    solution: {
        title: `${DEFAULT_TITLE} - 解决方案`,
        keywords: ['出海易', '解决方案', '出海易价格', '跨境成功案例', '跨境客户数据'],
        description: '出海易怎么入驻,出海易售卖多少钱,出海易的解决方案是什么,出海易的售卖主要分为起航版和扬帆版,起航版价格39800元,扬帆版30000元,想要了解出海易更多信息就登录出海易官网查看吧！',
    },
};

export function getSeoConfig(cfg: ISeoBaseConfig): HeadObject {
    const {
        title,
        keywords,
        description,
        dateUpdate,
        datePublished,
        dateLatestReply,
    } = cfg;
    return {
        title: title ?? DEFAULT_TITLE,
        meta: [
            {
                name: 'keywords',
                content: (keywords ?? DEFAULT_KEYWORDS).join(','),
            },
            {
                name: 'description',
                content: description ?? DEFAULT_DESCRIPTION,
            },
            {
                name: 'dateUpdate',
                content: dateUpdate ?? DEFAULT_DATETIME,
            },
            {
                name: 'datePublished',
                content: datePublished ?? DEFAULT_DATETIME,
            },
            {
                name: 'dateLatestReply',
                content: dateLatestReply ?? DEFAULT_DATETIME,
            },
        ],
    };
}

export const DEFAULT_SEO_CONFIG: HeadObject = getSeoConfig(seoBaseConfig.default);
export const HOME_SEO_CONFIG: HeadObject = getSeoConfig(seoBaseConfig.home);
export const NEWS_SEO_CONFIG: HeadObject = getSeoConfig(seoBaseConfig.news);
export const SOLUTION_SEO_CONFIG: HeadObject = getSeoConfig(seoBaseConfig.solution);
