/**
 * @file log 埋点上报方法
 * @author liuqifeng01@baidu.com
 */
import qs from 'qs';
import {inViewport} from '../utils/dom';
import {ILogConstructorParams, ISetLogParams, ILog, ISendParams} from './interface/log';
import {getQueryString} from '@/base/utils/url';

export default class Log implements ILog {
    static logInstance: ILog | null = null;
    readonly logUrl: string;
    private list: any[];
    private params: ILogConstructorParams;
    constructor(params: ILogConstructorParams) {
        this.logUrl = (params.isDev
            ? 'http://yq01-ps-201706-m12-9-www357.yq01.baidu.com:8880/report/a.gif'
            : 'https://istats.baidu.com/report/a.gif');
        this.list = [];
        this.params = {
            ...params,
        };
    }

    static useLogInstance(params: ILogConstructorParams) {
        return Log.logInstance = (Log.logInstance || new Log(params)) as ILog;
    }

    // install(app: App) {
    //     app.config.globalProperties.$log = Log.logInstance as ILog;
    // }

    send(params: ISendParams) {
        const url = encodeURIComponent(params.url ? params.url : window.location.href);

        params = {
            _t: new Date().getTime(),
            ...this.params,
            ...params,
            url,
        };
        delete params.isDev;

        // TODO from 字段做缓存？？？？？ - 加盟星的埋点逻辑里 from 字段存到了 storage 里
        const from = params.from || getQueryString('from');
        const ofr = params.ofr || getQueryString('ofr');
        const page = params.page || location.pathname;
        from && (params.from = from);
        ofr && (params.ofr = ofr);
        page && (params.page = page);

        const logUrl = `${this.logUrl}?${qs.stringify(params)}`;

        if (navigator.sendBeacon) {
            let retryTimes = 5;
            while (retryTimes-- > 0 && !navigator.sendBeacon(logUrl)) {
                // 加入队列失败时，重试5次
            }
        }
        else {
            let index = this.list.push(document.createElement('img')) - 1;

            this.list[index].onload = this.list[index].onerror = this.list[index].onabort = () => {
                this.list[index]
                    = this.list[index].onload
                    = this.list[index].onerror
                    = this.list[index].onabort
                    = null;
                delete this.list[index];
            };
            this.list[index].src = logUrl;
        }
    }

    setLogParams(params: ISetLogParams) {
        this.params = {
            ...this.params,
            ...params,
        };
    }

    sendViewAreaLog(ele: HTMLElement, params: ISendParams, partiallyVisible: boolean = true): void {
        // 第一次就在可视区域
        if (inViewport(ele, partiallyVisible)) {
            params && this.send(params);
        }
        else {
            const lazyLoading = () => {
                if (!inViewport(ele, partiallyVisible)) {
                    return;
                }

                params && this.send(params);

                window.removeEventListener('scroll', lazyLoading);
            };

            window.addEventListener('scroll', lazyLoading);
        }
    }
};
