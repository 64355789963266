import type {InjectionKey} from 'vue';
import {createStore, Store, useStore as baseUseStore} from 'vuex';
import createHomeStateModule, {HomeMutationEnum, IHomeState} from './home';
import createNewsState, {INewsState, NewsMutationEnum} from './news';
import userState, {IUserState} from './user';
import createOrderState, {IOrderState, OrderMutationEnum} from './order';
import createSeoListStateModule, {SeoListMutationEnum, ISeoListState} from './seolist';
import createLabelSearchListModule, {LabelSearchListMutationEnum, ILabelSearchListState} from './labelsearchlist';
import ceateSubjectSteateModule, {ISubjectState, SubjectMutationEnum} from './subject';
import {Ctx} from '@/entry/entry.server';

export interface IRootState {
    spiderInfo: SpiderInfo;
    headers?: {
        ck?: string;
        host?: string;
    };
    home?: IHomeState;
    news?: INewsState;
    user?: IUserState;
    order?: IOrderState;
    seolist?: ISeoListState;
    labelsearchlist?: ILabelSearchListState;
    subject?: ISubjectState;
};

export interface IModuleState {
    home: IHomeState;
    news: INewsState;
    user: IUserState;
    order: IOrderState;
    seolist: ISeoListState;
    labelsearchlist: ILabelSearchListState;
    subject: ISubjectState;
}

export interface IModuleMutation {
    home: HomeMutationEnum;
    news: NewsMutationEnum;
    order: OrderMutationEnum;
    seolist: SeoListMutationEnum;
    labelsearchlist: LabelSearchListMutationEnum;
    subject: SubjectMutationEnum;
}

interface SpiderInfo {
    spiderType: string;
    isSpider: boolean;
}

// 判断是否是spider
export function getSpiderInfo(ctx?: Ctx, ua?: string): SpiderInfo {
    let spiderRet = {
        spiderType: '',
        isSpider: false,
    };
    const spiderConf = [
        'baiduspider',
        'sogou web spider',
        '360spider',
        'bingbot',
        'googlebot',
    ];
    const userAgent = ctx?.req?.headers?.['user-agent'] || ua || '';
    spiderConf.forEach(item => {
        if (userAgent?.toLowerCase()?.includes(item)) {
            spiderRet = {
                spiderType: item,
                isSpider: item !== 'googlebot',
            };
        };
    });
    return spiderRet;
}

export const storeInjectionKey: InjectionKey<Store<IRootState & IModuleState>> = Symbol('storeInjectionKey');

export default function initStore(ctx?: Ctx) {
    return createStore<IRootState>({
        state: {
            spiderInfo: getSpiderInfo(ctx),
            headers: {
                ck: ctx?.req?.headers?.['cookie'] || '',
                host: ctx?.req.hostname,
            },
        },
        mutations: {},
        actions: {},
        modules: {
            home: createHomeStateModule(),
            news: createNewsState(),
            user: userState(),
            order: createOrderState(),
            seolist: createSeoListStateModule(),
            labelsearchlist: createLabelSearchListModule(),
            subject: ceateSubjectSteateModule(),
        },
        devtools: false,
    });
}

// 定义自己的 `useStore` 组合式函数
export function useStore() {
    return baseUseStore(storeInjectionKey);
}
