import type {Module} from 'vuex';
import {IRootState} from '.';
import {IHomeRes} from '@/base/api/interface/home';

export interface IHomeState {
    mergeHomeData: IHomeRes | undefined;
}

export enum HomeMutationEnum {
    SET_MERGE_HOME_DATA = 'SET_MERGE_HOME_DATA',
}

function createHomeStateModule(): Module<IHomeState, IRootState> {
    return {
        namespaced: true,
        state: {
            mergeHomeData: undefined,
        },
        mutations: {
            [HomeMutationEnum.SET_MERGE_HOME_DATA](state, payload: IHomeState['mergeHomeData']) {
                state.mergeHomeData = payload;
            },
        },
    };
}

export default createHomeStateModule;
