import {useSafeSSRContext} from '../hooks/useSafeSSRContext';

const {getConfig} = require('../../../env.config');
// 获取字符串长度、汉子 2、字母 1
export const getAbsStrLen = (str: string) => str.replace(/[^\x00-\xff]/g, '01').length;

export const IS_SERVER = typeof window === 'undefined' && typeof global === 'object';

export const IS_BROWNER = typeof window === 'object';

export const IS_NODE = process.env.WEBPACK_TARGET === 'node' || typeof window === 'undefined';

export const isMobileUa = (ua?: string) => {
    // eslint-disable-next-line max-len
    const regexp = /(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;

    if (IS_NODE) {
        const ssrCtx = useSafeSSRContext();
        if (ua) {
            return Boolean(regexp.exec(ua));
        }
        else if (ssrCtx && ssrCtx.ua) {
            return Boolean(regexp.exec(ssrCtx.ua));
        }
        return false;
    }

    const realUa = ua || navigator.userAgent;

    const isMobile = regexp.exec(realUa);

    // 当屏幕宽度小于 768px 时，也设为是移动端
    const width = document.documentElement.clientWidth;
    const isSmallScreen = width < 768;

    return !!isMobile || isSmallScreen;
};

export const loadScriptAsync = (url: string, callback?: () => void, opt?: {id?: string, charset?: 'utf-8'}) => {
    return new Promise((res, rej) => {
        const script = document.createElement('script');
        const opts = opt || {};

        script.type = 'text/javascript';

        if (opts.charset) {
            script.charset = opts.charset;
        }
        if (opts.id) {
            script.id = opts.id;
        }

        script.onload = function () {
            callback && callback();
            res(script);
        };

        script.onerror = err => {
            rej(err);
        };

        script.src = url;
        document.body.appendChild(script);
    });
};

export const getTrimmedString = (s: string) => {
    return s.trim();
};

// 数组分割
export const sliceArr = (data: any[], n: number) => {
    return data.reduce((arr, cur, index) => {
        const num = index % n; // 取余
        const currentArr = arr[arr.length - 1]; // 当前应该插入的数组

        if (num === 0) {
            arr.push([cur]);
        }
        else {
            currentArr.push(cur);
            arr[arr.length - 1] = currentArr;
        }

        return arr;
    }, []);
};

export const APP_CONFIG = getConfig();

export function getQueryInfo(): string {
    let query = (new URL(window.location.href)).search;
    if (query.startsWith('?')) {
        query = query.substring(1);
    }
    return query;
}

/**
 * 转义正则表达式特殊字符，转义后可以用于 RegExp，否则当匹配替换一个特殊字符时， 使用 replace 会报错
 * 如：'fds+fg'.replace(/+/g, '') 报错，因为 '+' 是正则里的一个特殊字符;
 * 需要转义的字符: $][)(*+.?\^{}
 * 比如： 'f+' => 'f\\+', 然后再使用 new RegExp('f\\+') 就可以匹配了
 *
 * @param str 要转义的字符串
 * @returns 返回转义后的字符串
 */
export function escapeRegexp(str: string): string {
    const escapeStr =  str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
    return escapeStr;
}

/**
 * 将字符串中的关键词高亮显示
 *
 * @param str 需要被处理的字符串
 * @param keywords 关键词或关键词数组，关键词之间以逗号分隔
 * @returns 高亮显示后的字符串
 */
export function highlightword({content, keywords}: {content: string, keywords: string | string[]}) {
    let keywordList: string[] = [];
    if (typeof keywords === 'string') {
        keywordList = keywords.split(',');
    }
    else if (keywords instanceof Array) {
        keywordList = keywords;
    }
    else {
        return content;
    }

    // 过滤掉空字符串项 然后转义正则特殊字符
    // 过滤掉空字符串的作用：防止空字符串被匹配然后替换
    // 例如 'fds'.replace(new RegExp('', 'ig'), '<em>$&</em>') ——> '<em></em>f<em></em>d<em></em>s<em></em>'
    const escapeKeywords: string[] = [];
    keywordList.forEach(word => {
        if (word.trim() !== '') {
            escapeKeywords.push(escapeRegexp(word));
        }
    });

    if (escapeKeywords.length <= 0) {
        return content;
    }

    const reg = new RegExp(`(${escapeKeywords.join('|')})`, 'ig');
    return content.replace(reg, '<em>$1</em>');
}
