import {Plugin} from 'vue';
import {IEncryptFont, IEncryptFontOption} from './types';
import {createFontStyleElement, decodeEncryptFont, getRandomFontSource} from './utils';

const encryptFontMap: Map<string, IEncryptFont> = new Map();

const encryptFontPlugin: Plugin = {
    install: (app, options: IEncryptFontOption) => {
        options.fonts.forEach(fontConfig => {
            const fontClass = fontConfig.class;
            const targetFontSource = getRandomFontSource(fontConfig.source);
            encryptFontMap.set(fontClass, {
                ...targetFontSource,
                class: fontClass,
            });
        });

        // 插入字体样式
        const styleTag = createFontStyleElement(encryptFontMap);
        document.head.appendChild(styleTag);

        // 全局注入加密字体 Map
        app.config.globalProperties.$encryptFontMap = encryptFontMap;

        // 注入 Vue 指令
        app.directive('decode-font', {
            mounted: (el: HTMLElement) => {
                const innerText = el.innerText;
                // bca-disable-line
                el.innerHTML = decodeEncryptFont(innerText);
            },
        });
    },
};

export const useEncryptFont = (fontClass?: string) => {
    const fontMap = encryptFontMap;
    const font = fontClass ? fontMap.get(fontClass) : undefined;

    return {
        fontMap,
        font,
    };
};

export default encryptFontPlugin;
