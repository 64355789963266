/* eslint-disable no-unused-expressions */
import {onMounted} from 'vue';

export const useVisible = ({onShow, onHide}: {onShow?: () => void, onHide?: () => void}) => {
    onMounted(() => {
        document.addEventListener('visibilitychange', function () {
            if (document.visibilityState === 'visible') {
                onShow && onShow();
            }
            else {
                onHide && onHide();
            }
        });
    });
};