import {cloneDeep} from 'lodash';
import {getApmInstance, getPageUrl, isApmReady} from './utils';
import {IS_NODE} from '@/base/utils';

export interface IUseLogServerConfig {
    // 是否手动开始记录时间
    autoStartLog?: boolean;
    // 期望记录的请求 url
    expReqUrl: string[];
}

interface ILogServerMapValue {
    isStarted: boolean;
    isFinished: boolean;
    reqStatus: Map<string, boolean>;
}

const LOG_SERVER_MAP: Map<string, ILogServerMapValue> = new Map();
const INIT_MAP_VALUE: ILogServerMapValue = {isStarted: false, isFinished: false, reqStatus: new Map()};

if (process.env.APP_MODE === 'development' && !IS_NODE) {
    window.LOG_SERVER_MAP = LOG_SERVER_MAP;
}

export function setLogServerMapValue(key: string, value: Partial<ILogServerMapValue>) {
    const mapValue = LOG_SERVER_MAP.get(key) || cloneDeep(INIT_MAP_VALUE);
    LOG_SERVER_MAP.set(key, {...mapValue, ...value});
}

export function tryStopLogServer(optId: string, reqUrl: string) {
    const mapValue = LOG_SERVER_MAP.get(optId);
    if (mapValue && !mapValue.isFinished && mapValue.reqStatus) {
        if (mapValue.reqStatus.get(reqUrl) !== undefined) {
            mapValue.reqStatus.set(reqUrl, true);
            // 如果想要记录的每个请求都访问完成了，则将其标记为 true
            if (Array.from(mapValue.reqStatus.values()).every(item => item === true)) {
                getApmInstance().logServer(1, getPageUrl());
                setLogServerMapValue(optId, {isFinished: true});
            }
        }
    }
}

/**
 * 用于计算每个页面的请求总耗时（sst），只统计影响页面交互的接口
 * @description https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/HDeySYhuyv/O-RKEhHIxVKcH6#anchor-571466e2-b252-11ec-b34c-6bfd89749842
 * @param config
 * @returns
 */
export default function useLogServer(config: IUseLogServerConfig) {
    const {autoStartLog = true, expReqUrl} = config;
    const optId = getApmInstance().getOptId();

    const initMapValue = () => {
        let currentMapValue = LOG_SERVER_MAP.get(optId);
        if (!currentMapValue) {
            LOG_SERVER_MAP.set(optId, cloneDeep(INIT_MAP_VALUE));
        }
        currentMapValue = LOG_SERVER_MAP.get(optId);
        // 添加 url 记录
        if (expReqUrl instanceof Array) {
            expReqUrl.forEach(url => {
                if (currentMapValue && !currentMapValue.reqStatus.get(url)) {
                    currentMapValue.reqStatus.set(url, false);
                }
            });
        }
    };

    /**
     * 开始 log server 记录
     */
    const startLogServer = () => {
        // 避免重复开始记录
        if (!LOG_SERVER_MAP.get(optId)?.isStarted) {
            getApmInstance().logServer(0, getPageUrl());
            setLogServerMapValue(optId, {isStarted: true});
        }
    };

    /**
     * 尝试停止 log server 记录，在每个想要记录的请求收到响应时执行
     */
    const _tryStopLogServer = (reqUrl: string) => {
        tryStopLogServer(optId, reqUrl);
    };

    initMapValue();
    if (autoStartLog && isApmReady()) {
        startLogServer();
    }

    return {
        startLogServer,
        tryStopLogServer: _tryStopLogServer,
    };
}
