/**
 * @file time.js
 * @author yangruifeng@baidu.com
 */

export const getDay = (day: number, separator: string): string => {
    const time = new Date();
    // 获取Day天后的日期
    time.setDate(time.getDate() + day);
    const y = `${time.getFullYear()}${(separator ? separator : '年')}`;
    // 获取当前月份的日期
    const m = `${(time.getMonth() + 1)}${(separator ? separator : '月')}`;
    const d = `${time.getDate()}${(separator ? '' : '日')}`;
    return y + m + d;
};

export const getMonth = (month: number, separator: string): string => {
    const time = new Date();
    // 获取Day天后的日期
    time.setDate(time.getDate());
    let y = time.getFullYear();
    let m = 0;
    if (time.getMonth() + month + 1 > 12) {
        y = y + 1;
        // 获取当前月份的日期 d
        m = time.getMonth() + month - 11;
    }
    else {
        // 获取当前月份的日期 d
        m = time.getMonth() + month + 1;
    }
    const d = time.getDate();
    return `${y}${(separator ? separator : '年')}${m}${(separator ? separator : '月')}${d}${(separator ? '' : '日')}`;
};

export const getYear = (year: number, separator: string): string => {
    const time = new Date();
    const y = `${time.getFullYear()}${year}${(separator ? separator : '年')}`;
    // 获取当前月份的日期
    const m = `${(time.getMonth() + 1)}${(separator ? separator : '月')}`;
    const d = `${time.getDate()}${(separator ? '' : '日')}`;
    return y + m + d;
};

/**
 * 计算日期后
 *
 * @param {number} time 事件
 * @param {string} unit 单位 d-天 w-周 m-月 y-年
 * @param {string} separator 分隔符，默认 年月日
 * @return {Object}
 */
export const getTime = (time: number, unit: string, separator: string): string => {
    const unitConf = {
        d: getDay(time, separator),
        w: getDay(time * 7, separator),
        m: getMonth(time, separator),
        y: getYear(time, separator),
    };
    return unitConf[unit];
};

/**
 * 时间戳格式化
 *
 * @param  {number} timeStamp  待格式化的时间戳
 * @param  {string} fmt        格式化字符串
 * @return {string}            格式化的日期
 */
export const timeStampFormat = (timeStamp: number, fmt: string = 'yyyy-MM-dd'): string => {
    if (!timeStamp) {
        return '';
    }
    // 兼容10位时间戳
    (`${timeStamp}`).length === 10 && (timeStamp = +(`${timeStamp}000`));
    timeStamp = +timeStamp;

    const date = new Date(timeStamp);
    const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        'S': date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    Object.keys(o).forEach(k => {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1)
                ? (o[k])
                : ((`00${o[k]}`).substr((`${o[k]}`).length)));
        }
    });
    return fmt;
};

/**
 * 时间戳格式化（from b2b-m）
 *
 * @param  {string | undefined} issueTime 文章时间字符串
 * @return {string}            格式化的日期
 */

export const formateIssuetime = (issueTime: string | undefined): string => {
    if (issueTime) {
        issueTime = issueTime.split(' ')[0].replace(/\./g, '-');
        return issueTime;
    }
    return '';
};
