import {logger} from './logger';

type LOG_TYPES = 'info' | 'warn' | 'error';

/**
 * aff 日志收集的日期格式 YYYY-MM-DD hh:mm:ss,SSS [INFO] [MESSAGE]
 * 时间戳 转化成 YYYY-MM-DD HH:mm:ss,SSS
 * @param date 时间
 * @param format 'YYYY-MM-DD HH:mm:ss,SSS'
 * @return 'YYYY-MM-DD HH:mm:ss,SSS'
 */
export function formatDate(date: Date = new Date(), format: string = 'YYYY-MM-DD HH:mm:ss,SSS'): string {
    date = date || new Date();

    const dateObj = {
        'Y+': date.getFullYear(),
        'M+': (date.getMonth() + 1).toString().padStart(2, '0'),
        'D+': date.getDate().toString().padStart(2, '0'),
        'H+': date.getHours().toString().padStart(2, '0'),
        'm+': date.getMinutes().toString().padStart(2, '0'),
        'ss+': date.getSeconds().toString().padStart(2, '0'),
        'S+': date.getMilliseconds(),
    };

    const arr = Object.keys(dateObj);
    arr.forEach((key: string) => {
        const reg = new RegExp('(' + key + ')');
        format = format.replace(reg, dateObj[key]);
    });

    return format;
}

export const nodeLog = (level: LOG_TYPES, ...rest) => {
    try {
        logger[level](rest.map(i => (typeof i === 'string' ? i : JSON.stringify(i, null, 2))).join(' '));
    }
    catch (error) {
        console.log('node log error', error);
    }
};
