import {computed, ComputedRef, InjectionKey, onMounted, provide} from 'vue';
// import {useRouter} from 'vue-router';
import {logoutUcAndSaas} from '@baidu/trade-util/utils/login';
// import {TRIAL_STATUS_ENUM} from '../api/interface/user';
import {useVisible} from './useVisible';
import {useStore} from '@/store';
import {IUserState} from '@/store/user';

export const USE_LOGIN_INJECT_KEY: InjectionKey<ComputedRef<IUserState>> = Symbol('BMS_USE_LOGIN_INJECT_KEY');
export const PASSPORT_ACCOUNT_STATUS_INJECT_KEY: InjectionKey<ComputedRef<boolean>>
    = Symbol('BMS_PASSPORT_ACCOUNT_STATUS_INJECT_KEY');
// export const IS_TRIALING_INJECT_KEY: InjectionKey<ComputedRef<boolean>>
//     = Symbol('BMS_IS_TRIALING_INJECT_KEY');

const USE_ONLINE_UC_SDK = (process.env.APP_MODE === 'production'
|| process.env.APP_MODE === 'online'
|| process.env.APP_MODE === 'preonline');

export function useLogin() {
    const {state, dispatch} = useStore();

    const userState = computed(() => state.user);

    const getUser = () => {
        dispatch('user/getUserDetail');
    };

    onMounted(() => {
        // 仅在客户端获取用户数据
        getUser();
    });

    useVisible({
        onShow: getUser,
    });

    const isPassportAccount = computed(() => {
        return Boolean(userState.value?.loginType === 'passport');
    });

    // const isTrialing = computed(() => {
    //     return Boolean(userState.value?.loginType === 'passport'
    //         && (userState.value.detail?.profit_status === TRIAL_STATUS_ENUM.trialing
    //             || userState.value.detail?.profit_status === TRIAL_STATUS_ENUM.dataTrialing
    //             || userState.value.detail?.profit_status === TRIAL_STATUS_ENUM.dataTrialingAndTrialExpired
    //         ));
    // });

    provide(USE_LOGIN_INJECT_KEY, userState);
    // provide(IS_TRIALING_INJECT_KEY, isTrialing);
    provide(PASSPORT_ACCOUNT_STATUS_INJECT_KEY, isPassportAccount);

    return {
        userState,
        isPassportAccount,
        // isTrialing,
    };
}

export function useLogoutUC() {
    const {commit} = useStore();
    // const router = useRouter();

    function logoutUC() {
        commit('user/setLoginOut');
        logoutUcAndSaas(USE_ONLINE_UC_SDK);
        // router.push('/');
    }

    return {
        logoutUC,
    };
}
