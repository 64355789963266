// server 端不会处理 css 样式，仅能在客户端入库引入
import '@/base/controller/tradeUi';
import '@/base/controller/element-plus';
import '@/assets/css/index.styl';
import * as Weirwood from '@baidu/weirwood-sdk';
import {ID_INJECTION_KEY} from 'element-plus';
import WeirwoodConfig from '../../weirwood.json';
import createApp from './main';
import {ILogSendParamsMap, logJson} from '@/base/log/json';
import {createLog} from '@/base/log';

const {app, router, store} = createApp();

app.provide(ID_INJECTION_KEY, {
    prefix: 2000,
    current: 0,
});

const options: IWeirwoodOptions = {
    common: {
        /** 构建ID，手动填写，也可以自动化生成（推荐，例如在 build.sh 中生成），例如上线日期等，只支持如下字符：英文字母(大小写)，数字(0-9)，下划线(_)，中划线(-)，点(.)，@ 符号 */
        buildid: WeirwoodConfig.buildid,

        /** 项目token，通过监控平台生成 */
        token: WeirwoodConfig.token,
    },

    // 性能独有参数配置，优先级高于通用参数配置
    perf: {
        sampleRate: 0.01,
        spa: true,
        history: true,
        ignoreUrls: [
            // 本地开发屏蔽错误发送，请按需修改
            'localhost',
            '127.0.0.1',
            'local.baidu.com',
            'leslie1.bcc-szwg.baidu.com',
        ],
    },

    error: {
        collectWindowErrors: true,
        collectUnhandledRejections: true,
        sampleRate: 0.01,
        ignoreUrls: [
            // 本地开发屏蔽错误发送，请按需修改
            'localhost',
            '127.0.0.1',
            'local.baidu.com',
        ],
        isApiException: resp => {
            return resp.status !== 200;
        },
    },
};

Weirwood.init(options);

const log = createLog<ILogSendParamsMap>(
    {
        tpl: 'trade-bms',
        biz: 'pc',
    },
    logJson as ILogSendParamsMap
);

app.use(log);

if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);

    window.__INITIAL_STATE__ = null;
}

router.isReady().then(() => {
    app.mount('#app', true);
});
