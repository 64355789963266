/**
 * @file dom 相关方法
 */

/**
 * 判断某个元素是否是在可视范围内
 *
 * @param {HTMLElement} ele DOM 元素对象
 * @param {boolean} [partiallyVisible=true] 是否部分出现算展现
 * @returns {boolean} boolean 元素是否在可视窗口内
 *
 * @todo 单测
 */
export const inViewport = (ele: HTMLElement, partiallyVisible: boolean = true): boolean => {
    const {top, bottom} = ele.getBoundingClientRect();
    const {innerHeight} = window;

    return partiallyVisible
        ? (top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)
        : top >= 0 && bottom <= innerHeight;
};

/** 滚动方法
 * @param  {} top=0
 * @param  {ScrollBehavior='smooth'} behavior
 */
export const scrollToTop = (top = 0, behavior: ScrollBehavior = 'smooth') => {
    window.scrollTo({
        top,
        behavior,
    });
};
